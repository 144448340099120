<ng-container *transloco="let t">
  <app-browser-warning></app-browser-warning>
  <div class="container test-header" [class.header--open]="sideBarOpen">
    <header>
      <div class="sidebar-toggle test-sidebar-toggle" (click)="toggleSidebar()">
        <fnx-icon iconColor="gallery" iconName="bars"></fnx-icon>
        <div class="sidebar-toggle-section" [ngSwitch]="section">
          <ng-container *ngSwitchCase="'dashboard'">
            {{ t('headerDashboardLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'farm'">
            {{ t('headerFarmLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'crop'">
            {{ t('headerCropLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'cattle'">
            {{ t('headerCattleLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'livestock'">
            {{ t('headerLiveStockLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'shop'">
            {{ t('headerComponentsLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'developer'">
            {{ t('headerDeveloperLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'map'">
            {{ t('headerMapsLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'farmMap'">
            {{ t('headerMapsLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'calendar'">
            {{ t('headerCalendarLink') }}
          </ng-container>
          <ng-container *ngSwitchCase="'admin'">
            {{ t('headerAdminLink') }}
          </ng-container>
        </div>
      </div>
      <div class="user">
        <div class="user-register" *ngIf="isDemoAccount">
          <a
            fnx-link
            [href]="t('demoRegistrationLinkTarget')"
            class="test-header-demo-link"
          >
            {{ t('demoRegisterLink') }}
          </a>
        </div>
        <fnx-menu-overflow class="user-menu-desktop">
          <fnx-menu-overflow-toggle class="test-header-action-toggle">
            <span class="user-name test-user-name">{{ userId }}</span>
            <fnx-icon
              iconName="triangle-down"
              iconColor="gallery"
              class="dropdown-icon"
            ></fnx-icon>
          </fnx-menu-overflow-toggle>
          <fnx-menu-overflow-item
            [appTrackNavigation]="{
              action: 'Top_L1::User_Menu::Desktop',
              label: 'Settings'
            }"
            routerLink="/admin/index"
            class="test-header-settings"
            iconName="settings"
          >
            {{ t('headerAdminLink') }}
          </fnx-menu-overflow-item>
          <fnx-menu-overflow-item
            [appTrackNavigation]="{
              action: 'Top_L1::User_Menu::Desktop',
              label: '*Help'
            }"
            (click)="openHelp()"
            class="test-header-help"
            iconName="help-circle"
          >
            {{ t('headerHelpLink') }}
          </fnx-menu-overflow-item>
          <fnx-menu-overflow-item
            [appTrackNavigation]="{
              action: 'Top_L1::User_Menu::Desktop',
              label: '*Logout'
            }"
            (click)="onLogout()"
            class="test-header-logout"
            iconName="logout"
            *ngIf="!isDemoAccount"
          >
            {{ t('headerLogoutLink') }}
          </fnx-menu-overflow-item>
        </fnx-menu-overflow>
      </div>
      <a
        routerLink="/"
        class="logo"
        alt="365FarmNet"
        [appTrackNavigation]="{ action: 'Top_L1', label: 'Dashboard Logo' }"
        (click)="onLogoClick()"
      >
      </a>
    </header>

    <div class="backdrop" (click)="closeSidebar()"></div>
    <nav class="sidebar-container test-nav">
      <ul class="nav-list">
        <li>
          <a
            class="nav-item nav-item--icon test-nav-item test-nav-item--dashboard"
            routerLink="/dashboard"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'dashboard'"
            #rla="routerLinkActive"
            (click)="closeSidebar()"
          >
            <fnx-icon
              iconName="building"
              iconHoverColor="mistgray"
              class="nav-item-dashboard-icon"
              [iconColor]="
                rla.isActive || section === 'dashboard'
                  ? 'honeyyellow'
                  : 'gallery'
              "
              (click)="closeSidebar()"
              [appTrackNavigation]="{
                action: 'Top_L1::Navigation::Left',
                label: 'Dashboard Home Icon'
              }"
            ></fnx-icon>
          </a>
          <a
            class="nav-item nav-item--text test-nav-item test-nav-item--dashboard dashboard-text"
            routerLink="/dashboard"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'dashboard'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Dashboard Text'
            }"
          >
            {{ t('headerDashboardLink') }}
          </a>
        </li>
        <li>
          <a
            class="nav-item test-nav-item test-nav-item--farm"
            routerLink="/farm"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'farm'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Farm'
            }"
          >
            {{ t('headerFarmLink') }}
          </a>
        </li>
        <li>
          <a
            class="nav-item test-nav-item test-nav-item--crop"
            routerLink="/crop"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'crop'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Crop'
            }"
          >
            {{ t('headerCropLink') }}
          </a>
        </li>
        <li *ngIf="showLiveStock">
          <a
            class="nav-item test-nav-item test-nav-item--livestock"
            [routerLink]="'/livestock'"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'livestock'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'LiveStock'
            }"
          >
            {{ t('headerLiveStockLink') }}
          </a>
        </li>
        <li *ngIf="showCattle">
          <a
            class="nav-item test-nav-item test-nav-item--cattle"
            routerLink="cattle"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'cattle'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Cattle'
            }"
          >
            {{ t('headerCattleLink') }}
          </a>
        </li>
        <li>
          <a
            class="nav-item test-nav-item test-nav-item--map"
            routerLink="/mapcenter"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'map'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Right',
              label: 'Maps'
            }"
          >
            {{ t('headerMapsLink') }}
          </a>
        </li>
        <li *ngIf="showShop">
          <a
            class="nav-item test-nav-item test-nav-item--shop"
            routerLink="/shop"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'shop'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Component'
            }"
          >
            {{ t('headerComponentsLink') }}
          </a>
        </li>
        <li *ngIf="developSectionEnabled">
          <a
            class="nav-item nav-item--developer test-nav-item test-nav-item--developer"
            routerLink="/connect"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'developer'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Left',
              label: 'Developer'
            }"
          >
            {{ t('headerDeveloperLink') }}
          </a>
        </li>
        <ng-container *fnxFeatureToggle="'teamfrontend.webapp-loader-test'">
          <li>
            <a
              style="font-size: 10px"
              class="nav-item test-nav-item test-nav-item--test-wlt"
              routerLink="/webapp-loader-test"
              routerLinkActive="routerlink--active"
              (click)="closeSidebar()"
              >wlt</a
            >
          </li>
          <li>
            <a
              style="font-size: 10px"
              class="nav-item test-nav-item test-nav-item--test-wlt-page"
              routerLink="/webapp-loader-test/page"
              routerLinkActive="routerlink--active"
              (click)="closeSidebar()"
              >wlt/page</a
            >
          </li>
          <li>
            <a
              style="font-size: 10px"
              class="nav-item test-nav-item test-nav-item--test-404"
              routerLink="/page-not-found"
              routerLinkActive="routerlink--active"
              (click)="closeSidebar()"
              >404</a
            >
          </li>
          <li>
            <a
              style="font-size: 10px"
              class="nav-item test-nav-item test-nav-item--test-sub"
              routerLink="/test-service"
              routerLinkActive="routerlink--active"
              (click)="closeSidebar()"
              >ts</a
            >
          </li>
        </ng-container>
      </ul>
      <ul class="nav-list">
        <li>
          <a
            class="nav-item test-nav-item test-nav-item--calendar"
            routerLink="/calendar"
            routerLinkActive="routerlink--active"
            [class.nav-item--active]="section === 'calendar'"
            (click)="closeSidebar()"
            [appTrackNavigation]="{
              action: 'Top_L1::Navigation::Right',
              label: 'Calendar'
            }"
          >
            {{ t('headerCalendarLink') }}
          </a>
        </li>
        <li>
          <a
            class="nav-item nav-item--icon nav-item--help test-nav-item test-nav-item--help"
          >
            <fnx-icon
              iconName="help-circle"
              class="nav-item-help-icon"
              iconHoverColor="mistgray"
              iconColor="gallery"
              (click)="openHelp()"
              [appTrackNavigation]="{
                action: 'Top_L1::Navigation::Right',
                label: '*Help'
              }"
            ></fnx-icon>
          </a>
        </li>
      </ul>
      <div class="sidebar-user">
        <div class="sidebar-user-name">{{ userName }}</div>
        <div class="sidebar-user-menu">
          <div class="test-sidebar-usermenu-toggle sidebar-user-id">
            {{ userId }}
          </div>
          <ul class="test-user-menu menu-list">
            <li
              [appTrackNavigation]="{
                action: 'Top_L1::User_Menu::Mobile',
                label: 'Settings'
              }"
              routerLink="/admin/index"
              class="test-header-sidebar-settings"
            >
              <fnx-icon
                iconName="settings"
                iconColor="gallery"
                class="user-icon"
              ></fnx-icon>
              <span>{{ t('headerAdminLink') }}</span>
            </li>
            <li
              [appTrackNavigation]="{
                action: 'Top_L1::User_Menu::Mobile',
                label: '*Help'
              }"
              (click)="openHelp()"
              class="test-header-sidebar-help"
            >
              <fnx-icon
                iconName="help-circle"
                iconColor="gallery"
                class="user-icon"
              ></fnx-icon>
              <span>{{ t('headerHelpLink') }}</span>
            </li>
            <li
              [appTrackNavigation]="{
                action: 'Top_L1::User_Menu::Mobile',
                label: '*Logout'
              }"
              (click)="onLogout()"
              class="test-header-sidebar-logout"
              *ngIf="!isDemoAccount"
            >
              <fnx-icon
                iconName="logout"
                iconColor="gallery"
                class="user-icon"
              ></fnx-icon>
              <span>{{ t('headerLogoutLink') }}</span>
            </li>
          </ul>
        </div>
      </div>
      <ul class="links">
        <li>
          <a
            class="test-terms"
            routerLink="/terms"
            target="_blank"
            fnx-link
            [appTrackNavigation]="{
              action: 'Top_L1',
              label: 'Terms of Use and Privacy Policy'
            }"
          >
            {{ t('headerTermsLink') }}
          </a>
        </li>
        <li>
          <a
            class="test-impressum"
            href="https://www.365farmnet.com/allgemeine-infos/impressum/"
            target="_blank"
            fnx-link
            [appTrackNavigation]="{ action: 'Top_L1', label: 'Legal Notice' }"
            >{{ t('headerImpressumLink') }}</a
          >
        </li>
      </ul>
    </nav>
  </div>
</ng-container>
