import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeServicePageComponent } from './iframe-service-page.component';
import { IframeModule } from 'src/app/components/iframe/iframe.module';

@NgModule({
  declarations: [IframeServicePageComponent],
  imports: [CommonModule, IframeModule],
  exports: [IframeServicePageComponent],
})
export class IframeServicePageModule {}
