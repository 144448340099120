import {
  Component,
  Input,
  EventEmitter,
  Output,
  AfterViewInit,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';
import { FnwSection } from '@farmnet/webapp-loader-companion';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @Input()
  isDemoAccount: boolean | null;
  @Input()
  showLiveStock: boolean | null;
  @Input()
  showCattle: boolean | null;
  @Input()
  developSectionEnabled: boolean | null;
  @Input()
  userId: string | null;
  @Input()
  section: FnwSection | null;
  @Input()
  userName: string | null;
  @Input()
  showShop: boolean | null;
  @Output()
  logout = new EventEmitter<void>();
  @Output()
  help = new EventEmitter<void>();
  @Output()
  height = new EventEmitter<number>();
  resizeObserver: ResizeObserver;

  constructor(private ref: ElementRef<Element>) {}

  sideBarOpen = false;

  closeSidebar() {
    this.sideBarOpen = false;
  }

  toggleSidebar() {
    this.sideBarOpen = !this.sideBarOpen;
  }

  onLogout() {
    this.logout.emit();
  }

  openHelp() {
    this.help.emit();
  }

  onLogoClick() {
    this.closeSidebar();
  }

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      const element = this.ref.nativeElement;
      this.height.emit(element.scrollHeight);
    });
    this.resizeObserver.observe(this.ref.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.ref.nativeElement);
    this.resizeObserver.disconnect();
  }
}
