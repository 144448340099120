import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';

import { map, skipWhile, switchMap } from 'rxjs/operators';
import { CredentialsActions, PartnerLogoutUrlActions } from '../actions';
import { CredentialsService } from '../services/credentials.service';

import * as fromCredentials from '../reducers';
import { select, Store } from '@ngrx/store';

@Injectable()
export class PartnerLogoutUrlEffects {
  constructor(
    private actions$: Actions<
      CredentialsActions.Union | PartnerLogoutUrlActions.Union
    >,
    private credentialsService: CredentialsService,
    private store: Store<fromCredentials.State>
  ) {}

  load$ = createEffect(() =>
    this.store.pipe(
      select(fromCredentials.getPartnerId),
      skipWhile((partnerId) => !partnerId),
      switchMap((partnerId) =>
        this.credentialsService.getLogoutUrlForPartner('barto')
      ),
      map((logoutUrlResponse) =>
        PartnerLogoutUrlActions.set({
          partnerLogoutUrl: logoutUrlResponse.data.logoutUrl,
        })
      )
    )
  );
}
