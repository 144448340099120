import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelpPage } from '../../reducers/help.reducer';
import { Store } from '@ngrx/store';
import * as fromCredentials from '../../credentials/reducers';
import { currentHelpPageIFrame } from '../../reducers';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  HelpPage = HelpPage;

  popupHeaderActions = [
    {
      iconName: 'new-window',
      click: this.openHelpInNewWindow.bind(this),
    },
  ];

  private open: boolean = false;
  @Input()
  set isOpen(isOpen: boolean) {
    this.open = isOpen;
  }
  get isOpen(): boolean {
    return this.open;
  }
  @Input()
  url: string = '';

  @Input()
  userLocale: string = '';

  @Output()
  close = new EventEmitter<void>();

  _currentIFrameSrc;
  _currentIFrameSrcSanitized;

  constructor(
    private store: Store<fromCredentials.State>,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.store.select(currentHelpPageIFrame).subscribe((iFrameSrc) => {
      this._currentIFrameSrc = iFrameSrc;
      this._currentIFrameSrcSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(
        iFrameSrc
      );
    });
  }

  openHelpInNewWindow() {
    window.open(this._currentIFrameSrc, '_blank');
    this.close.next();
  }
}
