import { NgModule } from '@angular/core';
import {
  FnxButtonIconTransparentModule,
  FnxFeatureToggleModule,
  FnxIconModule,
  FnxLinkModule,
  FnxMenuOverflowModule,
} from '@farmnet/fnx-components';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TrackNavigationModule } from '../../analytics/directives/track-navigation.module';
import { BrowserWarningModule } from '../browser-warning/browser-warning.module';
import { HeaderComponent } from './header.component';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    BrowserWarningModule,
    FnxIconModule,
    FnxLinkModule,
    FnxMenuOverflowModule,
    CommonModule,
    RouterModule,
    TrackNavigationModule,
    FnxButtonIconTransparentModule,
    FnxFeatureToggleModule,
    TranslocoModule,
  ],
  providers: [],
  exports: [HeaderComponent],
})
export class HeaderModule {}
