import { createAction, union, props } from '@ngrx/store';

export const set = createAction(
  '[ModuleInfo] Set Properties',
  props<{ isDeveloperMode: boolean; monolithSegments: string[] }>()
);

export const get = createAction('[ModuleInfo] Get Properties');

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  set,
  get,
});
export type Union = typeof all;
