import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeModule } from '../../components/iframe/iframe.module';
import { IframeExternalUrlWrapperComponent } from './iframe-external-url-wrapper.component';

@NgModule({
  declarations: [IframeExternalUrlWrapperComponent],
  imports: [CommonModule, IframeModule],
  exports: [IframeExternalUrlWrapperComponent],
})
export class IframeExternalUrlWrapperModule {}
