import { Inject, Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  catchError,
  filter,
  map,
  observeOn,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { asyncScheduler, interval, Observable, of } from 'rxjs';
import { CredentialsActions } from '../actions';
import { CredentialsService } from '../services/credentials.service';
import { Action, select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { CookieService } from '../../services/cookie.service';
import { BroadcastActions } from '../../actions';
import * as fromRoot from '../../reducers';
import { selectUrl } from '../../reducers';
import { LocaleService } from '../services/locale.service';
import { enterZone, leaveZone } from '../../services/leave-zone-scheduler';
import { getPartnerLogoutUrl, getUserLocale } from '../reducers';
import { IFrameService } from '../../services/iframe.service';
import { UserlaneService } from '../../services/userlane.service';
import {
  FnwCredentialsService,
  LOCATION,
} from '@farmnet/webapp-loader-companion';

@Injectable()
export class CredentialsEffects {
  constructor(
    private actions$: Actions<CredentialsActions.Union>,
    private credentialsService: CredentialsService,
    private fnwCredentialsService: FnwCredentialsService,
    private router: Router,
    private cookieService: CookieService,
    private store: Store<fromRoot.State>,
    private localeService: LocaleService,
    private ngZone: NgZone,
    private iframeService: IFrameService,
    private userlaneService: UserlaneService,
    @Inject(LOCATION) private location: Location
  ) {}

  loadCredentials$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CredentialsActions.loadCredentials.type),
        switchMap(() => this.fnwCredentialsService.keepAlive())
      ),
    {
      dispatch: false,
    }
  );

  updateCredentials$ = createEffect(() =>
    this.fnwCredentialsService.credentials.pipe(
      withLatestFrom(
        this.store.pipe(select(selectUrl)),
        this.store.pipe(select(getUserLocale))
      ),
      switchMap(([credentials, url, locale]) => {
        if (credentials.loggedIn) {
          const newLocale = credentials.locale;

          if (locale && locale !== newLocale) {
            const redirectAction = CredentialsActions.applyNewLocale({
              locale: newLocale,
            });
            return [
              redirectAction,
              BroadcastActions.broadcast({ action: redirectAction }),
            ];
          }
          const action = CredentialsActions.loadCredentialsSuccess({
            credentials,
          });

          return [action, BroadcastActions.broadcast({ action })];
        }
        return of(
          CredentialsActions.redirectToLogin({
            requestedRoute: url,
          })
        );
      }),
      catchError((error) => {
        return of(CredentialsActions.loadCredentialsFailure({ error }));
      })
    )
  );

  loadCredentialsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CredentialsActions.loadCredentialsSuccess.type),
        tap((action) => {
          this.iframeService.updateCredentials(action.credentials);
          this.userlaneService.init(action.credentials);
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CredentialsActions.logout.type),
      switchMap(() =>
        this.credentialsService.logout().pipe(catchError(() => of(null)))
      ),
      switchMap(() => {
        this.cookieService.remove('currentCalenderViewName');
        this.cookieService.remove('hideDemoAccountOverlay');

        const logoutSuccess = CredentialsActions.logoutSuccess();

        return [
          logoutSuccess,
          BroadcastActions.broadcast({
            action: logoutSuccess,
          }),
        ];
      })
    )
  );

  logoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CredentialsActions.logoutSuccess.type),
      map(() => CredentialsActions.redirectToLogin())
    )
  );

  redirectToLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CredentialsActions.redirectToLogin.type),
        withLatestFrom(this.store.pipe(select(getPartnerLogoutUrl))),
        tap(([action, partnerLogoutUrl]) => {
          if (partnerLogoutUrl) {
            this.router.navigate(['/external', { url: partnerLogoutUrl }]);
          } else {
            const url = this.localeService.getLocalizedLoginUrl(
              action.requestedRoute
            );
            this.router.navigate(['/external', { url }]);
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  redirectToLocalizedUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CredentialsActions.applyNewLocale.type),
        withLatestFrom(this.store.pipe(select(selectUrl))),
        tap(([action, url]) => {
          this.location.reload();
        })
      ),
    {
      dispatch: false,
    }
  );

  loadCredentialsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CredentialsActions.loadCredentialsFailure.type),
      map(() => CredentialsActions.redirectToLogin())
    )
  );

  keepAlive$ = createEffect(
    () => ({
      interval: inter = 10 * 60 * 1000,
      scheduler = leaveZone(this.ngZone, asyncScheduler), // leave angular zone, to not block protractor
    } = {}) => {
      return interval(inter, scheduler).pipe(
        withLatestFrom(this.store.pipe(select(fromRoot.isLead))),
        filter(([, isLeader]) => isLeader),
        observeOn(enterZone(this.ngZone, scheduler)),
        switchMap(() => this.fnwCredentialsService.keepAlive())
      );
    },
    {
      dispatch: false,
    }
  );
}
