import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../reducers';
import * as fromCredentials from './credentials.reducer';
import { Right } from '../models/right';

export interface State extends fromRoot.State {
  credentials: fromCredentials.State;
}

export const selectCredentialsState = createFeatureSelector<
  State,
  fromCredentials.State
>('credentials');

export const isCredentialsLoading = createSelector(
  selectCredentialsState,
  fromCredentials.isLoading
);

export const getCredentials = createSelector(
  selectCredentialsState,
  fromCredentials.get
);

export const isLoggedIn = createSelector(
  selectCredentialsState,
  fromCredentials.isLoggedIn
);

export const isDemoAccount = createSelector(
  selectCredentialsState,
  fromCredentials.isDemoAccount
);

export const getUserId = createSelector(
  selectCredentialsState,
  fromCredentials.getUserId
);

export const getPartnerId = createSelector(
  selectCredentialsState,
  fromCredentials.getPartnerId
);

export const getUserName = createSelector(
  selectCredentialsState,
  fromCredentials.getUserName
);

export const getRelease = createSelector(
  selectCredentialsState,
  fromCredentials.getRelease
);

export const isShopCountry = createSelector(
  selectCredentialsState,
  fromCredentials.isShopCountry
);

export const getCountryCode = createSelector(
  selectCredentialsState,
  fromCredentials.getCountryCode
);

export const getCustomerNumber = createSelector(
  selectCredentialsState,
  fromCredentials.getCustomerNumber
);

export const getPerson = createSelector(
  selectCredentialsState,
  fromCredentials.getPerson
);

export const getCompany = createSelector(
  selectCredentialsState,
  fromCredentials.getCompany
);

export const getSettings = createSelector(
  selectCredentialsState,
  fromCredentials.getSettings
);

export const getUserLocale = createSelector(
  selectCredentialsState,
  fromCredentials.getUserLocale
);

export const getPartnerLogoutUrl = createSelector(
  selectCredentialsState,
  fromCredentials.getPartnerLogoutUrl
);

export const getRights = createSelector(
  selectCredentialsState,
  fromCredentials.getRights
);

export const isProductAvailable = (product: string) =>
  createSelector(selectCredentialsState, (state: fromCredentials.State) =>
    fromCredentials.isProductAvailable(state, product)
  );

export const hasProduct = (product: string) =>
  createSelector(selectCredentialsState, (state: fromCredentials.State) =>
    fromCredentials.hasProduct(state, product)
  );

export const hasRight = (right: Right) =>
  createSelector(selectCredentialsState, (state: fromCredentials.State) =>
    fromCredentials.hasRight(state, right)
  );
