import { BroadcastActions } from '../actions/';

export interface State {
  isLead: boolean;
}

export const initialState: State = {
  isLead: false,
};

export function reducer(
  state = initialState,
  action: BroadcastActions.BroadcastActionsUnion
): State {
  switch (action.type) {
    case BroadcastActions.isLeader.type:
      return {
        ...state,
        isLead: true,
      };
    default:
      return state;
  }
}

export const isLead = (state: State) => state.isLead;
