import { Component } from '@angular/core';
import LOGO from '../../../assets/images/logo-with-text.svg';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  LOGO = LOGO;
}
