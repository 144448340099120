import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

import * as fromCredentials from '../reducers/';
import { CredentialsActions } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class CredentialsLoadedGuard implements CanActivate {
  constructor(private store: Store<fromCredentials.State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromCredentials.selectCredentialsState),
      skipWhile(({ loading }) => loading),
      map(({ credentials }) => {
        if (credentials) {
          if (credentials.loggedIn) {
            return true;
          }

          throw new Error('unexpected state credentials should be logged in');
        }

        throw new Error(
          'unexpected state credentials should be loaded if loading is finished'
        );
      }),
      take(1)
    );
  }
}
