import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframePageComponent } from './iframe-page.component';
import { RouterModule } from '@angular/router';
import { PageNotFoundModule } from '../../components/page-not-found/page-not-found.module';

@NgModule({
  declarations: [IframePageComponent],
  imports: [CommonModule, RouterModule, PageNotFoundModule],
  exports: [IframePageComponent],
})
export class IframePageModule {}
