import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take, skipWhile } from 'rxjs/operators';

import * as fromModuleInfo from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class ModuleInfoLoadedGuard implements CanActivate {
  constructor(private store: Store<fromModuleInfo.State>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(fromModuleInfo.selectModuleInfoState),
      skipWhile(
        ({ monolithSegments }) =>
          !monolithSegments || monolithSegments.length <= 0
      ),
      map(({ monolithSegments }) => {
        if (monolithSegments) {
          if (monolithSegments && monolithSegments.length > 0) {
            return true;
          }
        }

        return false;
      }),
      take(1)
    );
  }
}
