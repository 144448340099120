import { HelpActions } from '../actions/';

export enum HelpPage {
  INFO,
  CONTACT,
  DATE,
  REMOTEMAINTENANCE,
}

export interface State {
  isOpen: boolean;
  currentPage: HelpPage;
  currentIFrameSrc: string;
}

export const initialState: State = {
  isOpen: false,
  currentPage: HelpPage.INFO,
  currentIFrameSrc: '',
};

export function reducer(
  state = initialState,
  action: HelpActions.HelpActionsUnion
): State {
  switch (action.type) {
    case HelpActions.open.type:
      return {
        ...state,
        isOpen: true,
      };
    case HelpActions.toggle.type:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case HelpActions.close.type:
      return {
        ...state,
        isOpen: false,
      };
    case HelpActions.changePage.type:
      return {
        ...state,
        currentPage: action.page,
        currentIFrameSrc: action.currentIFrameSrc,
      };
    default:
      return state;
  }
}

export const isOpen = (state: State) => state.isOpen;
export const currentHelpPage = (state: State) => state.currentPage;
export const currentIFrameSrc = (state: State) => state.currentIFrameSrc;
