import {
  TRANSLOCO_LOADER,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TranslocoService,
  TranslocoLoader,
  Translation,
} from '@ngneat/transloco';
import {
  APP_INITIALIZER,
  Injectable,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { environment } from '../environments/environment';
import {
  loadLanguage,
  localeId,
} from '@farmnet/webapp-loader-companion/transloco';
import {
  FnwConfig,
  FnwCredentialsService,
  loadCredentials,
} from '@farmnet/webapp-loader-companion';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    return fromPromise(import(`../assets/i18n/${lang}.json`)).pipe(
      map((response) => response.default)
    );
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        // dialect after base language
        availableLangs: [
          'de-DE',
          'de-CH',
          'en-US',
          'pl',
          'fr-FR',
          'fr-CH',
          'bg',
        ],
        fallbackLang: 'de-DE',
        reRenderOnLangChange: false,
        prodMode: environment.production,
        missingHandler: {
          allowEmpty: true,
          useFallbackTranslation: true,
        },
        flatten: {
          aot: environment.production,
        },
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: loadCredentials,
      deps: [FnwCredentialsService],
      multi: true,
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadLanguage,
      deps: [FnwCredentialsService, TranslocoService],
    },
    {
      provide: LOCALE_ID,
      useFactory: localeId,
      deps: [TranslocoService],
    },
    {
      provide: FnwConfig,
      useValue: {},
    },
  ],
})
export class TranslocoRootModule {}
