import { createAction, props, union } from '@ngrx/store';
import { FnwSection } from '@farmnet/webapp-loader-companion';

export interface ScrollActionPayload {
  scrollY: number;
  scrollMaxY: number;
}

export const fnwLocationUpdate = createAction(
  '[Fnw] Location Update',
  props<{
    url: string;
    section: FnwSection | null;
    serviceName: string;
  }>()
);

export const fnwSectionChange = createAction(
  '[Fnw] Section Change',
  props<{ section: FnwSection }>()
);

export const fnwScrollChange = createAction(
  '[Fnw] Scroll Change',
  props<ScrollActionPayload>()
);

export const fnwHeightChange = createAction(
  '[Fnw] Height Change',
  props<ScrollActionPayload>()
);

export const fnwCredentialsChange = createAction('[Fnw] Credentials Change');

export const fnwAllowNavigateInService = createAction(
  '[Fnw] Allow navigate in service',
  props<{ url: string }>()
);

export const fnwRejectNavigateInService = createAction(
  '[Fnw] Reject navigate in service',
  props<{ url: string }>()
);

export const fnwNavigateToService = createAction(
  '[Fnw] Navigate to service',
  props<{ url: string }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  fnwLocationUpdate,
  fnwSectionChange,
  fnwScrollChange,
  fnwHeightChange,
  fnwCredentialsChange,
  fnwNavigateToService,
  fnwAllowNavigateInService,
  fnwRejectNavigateInService,
});
export type FnwActionsUnion = typeof all;
