import { Component } from '@angular/core';
import { BrowserWarningService } from './browser-warning.service';

@Component({
  selector: 'app-browser-warning',
  templateUrl: './browser-warning.component.html',
  styleUrls: ['./browser-warning.component.scss'],
})
export class BrowserWarningComponent {
  constructor(public browserWarningService: BrowserWarningService) {}
}
