import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeMonolithPageComponent } from './iframe-monolith-page.component';
import { IframeModule } from '../../components/iframe/iframe.module';

@NgModule({
  declarations: [IframeMonolithPageComponent],
  imports: [CommonModule, IframeModule],
  exports: [IframeMonolithPageComponent],
})
export class IframeMonolithPageModule {}
