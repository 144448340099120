import { Injectable } from '@angular/core';
import { isSameUrl, isDifferentUrl } from '../helpers/compare-url';

@Injectable({
  providedIn: 'root',
})
export class CompareUrlService {
  isSameUrl = isSameUrl;
  isDifferentUrl = isDifferentUrl;
}
