import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Inject,
} from '@angular/core';
import { map, filter } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { getIframeUrl, isIframeLoading, State } from '../../reducers';
import { select, Store } from '@ngrx/store';
import { ResizeObserver } from '@juggle/resize-observer';
import { IframeComponent } from 'src/app/components/iframe/iframe.component';
import { IFrameService } from '../../services/iframe.service';
import { BehaviorSubject } from 'rxjs';
import { RESIZE_OBSERVER } from '../../services/resize-observer';

@Component({
  templateUrl: './iframe-monolith-page.component.html',
  styleUrls: ['./iframe-monolith-page.component.scss'],
  selector: 'app-iframe-monolith-page',
})
export class IframeMonolithPageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('appIframe')
  iframe: IframeComponent;

  monolithRoute$ = this.store.pipe(
    select(getIframeUrl),
    filter((url) => url !== null && url !== undefined),
    map((url) => {
      if (this.iframeService.getMonolithRoute() === url) {
        this.loading$.next(false);
      }
      return environment.monolithPrefix + '#' + url;
    })
  );
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  resizeObserver: ResizeObserver;

  constructor(
    private store: Store<State>,
    private iframeService: IFrameService,
    @Inject(RESIZE_OBSERVER)
    private ResizeObserverCreator: typeof ResizeObserver
  ) {
    this.store.pipe(select(isIframeLoading)).subscribe((value) => {
      this.loading$.next(value);
    });
  }

  ngAfterViewInit() {
    this.resizeObserver = new this.ResizeObserverCreator(
      (entries, observer) => {
        this.iframe.triggerScroll();
      }
    );
    this.resizeObserver.observe(document.body);
    this.iframeService.setMonolithIframe(this.iframe.iframe.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(document.body);
    this.resizeObserver.disconnect();
  }
}
