import { Inject, Injectable } from '@angular/core';
import {
  FnwNavigationService,
  LOCATION,
} from '@farmnet/webapp-loader-companion';
import { select, Store } from '@ngrx/store';
import { getCountryCode } from '../credentials/reducers';
import * as fromCredentials from '../credentials/reducers';

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlIframeDataService {
  countryCode: string | null;

  constructor(
    private navigationService: FnwNavigationService,
    private store: Store<fromCredentials.State>
  ) {
    this.store
      .pipe(select(getCountryCode))
      .subscribe((code) => (this.countryCode = code));
  }

  public prepareNavigationForKey(key: string) {
    if (key === 'cattle') {
      this.navigationService.setSection('cattle');
      console.log('i m here ' + window.location.hostname);
      if (location.hostname.startsWith('preview')) {
        return 'https://fn-demo.uniform-agri.com/login?farmnet=true';
      }

      return `https://eu.myherdmanagement.com/login?farmnet=true&country=${this.countryCode}`;
    }
    return '';
  }
}
