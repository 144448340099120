import { NgModule } from '@angular/core';
import { FnxLoadingModule } from '@farmnet/fnx-components';
import { CommonModule } from '@angular/common';
import { IframeComponent } from './iframe.component';

@NgModule({
  declarations: [IframeComponent],
  imports: [FnxLoadingModule, CommonModule],
  exports: [IframeComponent],
})
export class IframeModule {}
