import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AnalyticsActions } from '../actions';
import { tap, map } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics.service';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions<AnalyticsActions.AnalyticsActionsUnion>,
    private analyticsService: AnalyticsService
  ) {}

  trackNavigation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.trackNavigation.type),
        tap((action) => this.analyticsService.trackNavigation(action))
      ),
    { dispatch: false }
  );

  trackVirtualPath$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.trackVirtualPath.type),
        tap((action) => this.analyticsService.trackVirtualPath(action))
      ),
    { dispatch: false }
  );

  trackRouterNavigationEnd$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map((action: RouterNavigatedAction) =>
        AnalyticsActions.trackVirtualPath({
          title: '', // TODO?: implement lookup table
          virtPath: action.payload.event.url,
        })
      )
    )
  );

  trackProductEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AnalyticsActions.trackProductEvent.type),
        tap((action) => this.analyticsService.trackProductEvent(action))
      ),
    { dispatch: false }
  );
}
