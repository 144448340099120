import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { isIframeLoading, State } from '../../reducers';
import { ActivatedRoute } from '@angular/router';
import { ExternalUrlIframeDataService } from '../../services/external-url-iframe-data.service';

@Component({
  selector: 'app-iframe-external-url-wrapper',
  templateUrl: './iframe-external-url-wrapper.component.html',
  styleUrls: ['./iframe-external-url-wrapper.component.scss'],
})
export class IframeExternalUrlWrapperComponent implements OnInit {
  constructor(
    private store: Store<State>,
    private activatedRoute: ActivatedRoute,
    private dataService: ExternalUrlIframeDataService
  ) {}

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  url$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  ngOnInit(): void {
    this.activatedRoute.data.subscribe((data) => {
      this.url$.next(this.dataService.prepareNavigationForKey(data.key));
      this.loading$.next(false);
    });
  }
}
