import { createAction, union, props } from '@ngrx/store';

export const set = createAction(
  '[LogoutUrl] Set',
  props<{ partnerLogoutUrl: string }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  set,
});
export type Union = typeof all;
