import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { AnalyticsEffects } from './effects/analytics.effects';
import { TrackNavigationModule } from './directives/track-navigation.module';

@NgModule({
  imports: [
    EffectsModule.forFeature([AnalyticsEffects]),
    TrackNavigationModule,
  ],
  exports: [TrackNavigationModule],
})
export class AnalyticsModule {}
