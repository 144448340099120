import { Injectable } from '@angular/core';
import { Browser, detect } from 'detect-browser';
const browser = detect();

let isBrowserSupported = true;
const UNSUPPORTED__BROWSERS: Browser[] = ['ie'];

if (browser) {
  if (browser.type === 'browser') {
    isBrowserSupported = !UNSUPPORTED__BROWSERS.includes(browser.name);
  }
}

@Injectable({
  providedIn: 'root',
})
export class BrowserWarningService {
  readonly isBrowserSupported: boolean = isBrowserSupported;
}
