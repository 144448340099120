import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { ExternalUrlResolver } from './services/external-url.resolver';
import { EmptyComponent } from './components/empty/empty.component';
import { CredentialsLoadedGuard } from './credentials/services/credentials-loaded.guard';
import { IframeServicePageComponent } from './container/iframe-service-page/iframe-service-page.component';
import { IframeMonolithPageComponent } from './container/iframe-monolith-page/iframe-monolith-page.component';
import { IframePageComponent } from './container/iframe-page/iframe-page.component';
import { IframeGuard } from './services/iframe.guard';
import { ModuleInfoLoadedGuard } from './services/module-info-loaded.guard';
import { LanguageRedirectGuard } from './services/language-redirect.guard';
import { IframeExternalUrlWrapperModule } from './container/iframe-external-url-wrapper/iframe-external-url-wrapper.module';
import { IframeExternalUrlWrapperComponent } from './container/iframe-external-url-wrapper/iframe-external-url-wrapper.component';

// redirect old angular-i18n routes
const redirectRoutes = ['de', 'en', 'pl', 'fr', 'bg'].map(
  (language): Route => {
    return {
      path: language,
      canActivate: [LanguageRedirectGuard],
      children: [
        {
          path: '**',
          component: EmptyComponent,
        },
      ],
    };
  }
);

const externalIframeRoutes = [
  {
    path: 'cattle',
    component: IframeExternalUrlWrapperComponent,
    data: { key: 'cattle' },
  },
];

const routes: Routes = [
  {
    path: 'external',
    resolve: {
      url: ExternalUrlResolver,
    },
    // We need a component here because we cannot define the route otherwise
    component: EmptyComponent,
  },
  ...redirectRoutes,
  ...externalIframeRoutes,
  {
    path: '**',
    canActivate: [CredentialsLoadedGuard, ModuleInfoLoadedGuard],
    canDeactivate: [IframeGuard],
    component: IframePageComponent,
    children: [
      {
        path: '',
        component: IframeMonolithPageComponent,
        outlet: 'monolith',
      },
      {
        path: '',
        component: IframeServicePageComponent,
        outlet: 'service',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class WebappLoaderRoutingModule {}
