<div
  [style.top.px]="top$ | async"
  [style.height]="height$ | async"
  class="iframe-page-container"
>
  <div
    [style.display]="(showMonolith$ | async) ? 'block' : 'none'"
    class="test-monolith-iframe"
  >
    <router-outlet name="monolith"></router-outlet>
  </div>
  <div
    [style.display]="(showService$ | async) ? 'block' : 'none'"
    class="test-service-iframe"
  >
    <router-outlet name="service"></router-outlet>
  </div>
  <app-page-not-found *ngIf="showPageNotFound$ | async"></app-page-not-found>
</div>
