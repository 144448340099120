import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserWarningComponent } from './browser-warning.component';
import { FnxAlertModule, FnxLinkModule } from '@farmnet/fnx-components';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [BrowserWarningComponent],
  imports: [CommonModule, FnxAlertModule, FnxLinkModule, TranslocoModule],
  exports: [BrowserWarningComponent],
})
export class BrowserWarningModule {}
