import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCredentials from '../../credentials/reducers';
import { CredentialsActions } from '../../credentials/actions';
import {
  getIframeSection,
  isHelpOpen,
  isDeveloperMode,
  getHeaderTop,
  selectUrl,
  getFooterTop,
} from '../../reducers';
import { HelpActions, IFrameActions } from '../../actions';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCountryCode, getUserLocale } from '../../credentials/reducers';
import { FeatureToggleService } from '@farmnet/fnx-components';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  credentialsLoading$ = this.store.pipe(
    select(fromCredentials.isCredentialsLoading)
  );

  isDemoAccount$ = this.store.pipe(select(fromCredentials.isDemoAccount));
  userId$ = this.store.pipe(select(fromCredentials.getUserId));
  section$ = this.store.pipe(select(getIframeSection));
  release$ = this.store.pipe(select(fromCredentials.getRelease));
  userName$ = this.store.pipe(select(fromCredentials.getUserName));
  isHelpOpen$ = this.store.pipe(select(isHelpOpen));
  isDeveloperMode$ = this.store.pipe(select(isDeveloperMode));
  headerTop$ = this.store.pipe(select(getHeaderTop));
  footerTop$ = this.store.pipe(select(getFooterTop));
  isShopCountry$ = this.store.pipe(select(fromCredentials.isShopCountry));

  hasRightCompanyUpdate$ = this.store.pipe(
    select(fromCredentials.hasRight('Company:UPDATE'))
  );
  countryCode$ = this.store.pipe(select(getCountryCode));
  showShop$ = combineLatest([
    this.isShopCountry$,
    this.hasRightCompanyUpdate$,
  ]).pipe(
    map(
      ([isShopCountry, hasRightCompanyUpdate]) =>
        isShopCountry && hasRightCompanyUpdate
    )
  );

  url$ = this.store.pipe(select(selectUrl));
  userLocale$ = this.store.pipe(select(getUserLocale));
  userSettings$ = this.store.pipe(select(fromCredentials.getSettings));

  constructor(
    private store: Store<fromCredentials.State>,
    private featureToggle: FeatureToggleService
  ) {}

  logout() {
    this.store.dispatch(CredentialsActions.logout());
  }

  closeHelp() {
    this.store.dispatch(HelpActions.close());
  }

  onHelp() {
    this.store.dispatch(HelpActions.toggle());
  }

  emitHeaderHeight(height: number) {
    this.store.dispatch(IFrameActions.headerHeightChange({ height }));
  }

  emitFooterHeight(height: number) {
    this.store.dispatch(IFrameActions.footerHeightChange({ height }));
  }
}
