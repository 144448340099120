import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import { FnwActions, IFrameActions } from '../actions';
import { Router } from '@angular/router';
import { asyncScheduler, fromEvent } from 'rxjs';
import { CredentialsActions } from '../credentials/actions';
import { IFrameService } from '../services/iframe.service';
import { parseUrl } from 'query-string';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { isIframeScrollDown, isMonolith, isNavigating } from '../reducers';
import { FnwSendMessage } from '@farmnet/webapp-loader-companion';
import { AnalyticsActions } from '../analytics/actions';
import { CompareUrlService } from '../services/compare-url.service';

@Injectable()
export class FnwEffects {
  constructor(
    private actions$: Actions<FnwActions.FnwActionsUnion | IFrameActions.Union>,
    private store$: Store<fromRoot.State>,
    private iframeService: IFrameService,
    private router: Router,
    private compareUrlService: CompareUrlService
  ) {}

  fnwMessage$ = createEffect(() =>
    fromEvent(window, 'message').pipe(
      filter((event: MessageEvent) => event.origin === location.origin),
      map((event: MessageEvent): FnwSendMessage => event.data),
      ofType(
        'fnw-location-update',
        'fnw-scroll-change',
        'fnw-height-change',
        'fnw-credentials-change',
        'fnw-section-change',
        'fnw-navigate-to-service',
        'fnw-allow-navigation',
        'fnw-reject-navigation',
        'fnw-track-product-event'
      ),
      map((data: FnwSendMessage) => {
        switch (data.type) {
          case 'fnw-location-update':
            return FnwActions.fnwLocationUpdate({
              url: data.url,
              section: data.section,
              serviceName: data.serviceName,
            });
          case 'fnw-section-change':
            return FnwActions.fnwSectionChange({
              section: data.section,
            });
          case 'fnw-scroll-change':
            return FnwActions.fnwScrollChange({
              scrollY: data.scrollY,
              scrollMaxY: data.scrollMaxY,
            });
          case 'fnw-height-change':
            return FnwActions.fnwHeightChange({
              scrollY: data.scrollY,
              scrollMaxY: data.scrollMaxY,
            });
          case 'fnw-navigate-to-service':
            return FnwActions.fnwNavigateToService({
              url: data.url,
            });
          case 'fnw-credentials-change':
            return FnwActions.fnwCredentialsChange();
          case 'fnw-allow-navigation':
            return FnwActions.fnwAllowNavigateInService({
              url: data.url,
            });
          case 'fnw-reject-navigation':
            return FnwActions.fnwRejectNavigateInService({
              url: data.url,
            });
          case 'fnw-track-product-event':
            return AnalyticsActions.trackProductEvent(data);
          default:
            throw new Error('unexpected state');
        }
      })
    )
  );

  fnwNavigateToService$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FnwActions.fnwNavigateToService.type),
        tap((action) => {
          const parsedUrl = parseUrl(action.url);
          this.router.navigate([parsedUrl.url], {
            queryParams: parsedUrl.query,
            state: {
              toService: true,
            },
          });
        })
      ),
    { dispatch: false }
  );

  fnwCredentialsChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnwActions.fnwCredentialsChange.type),
      map(() => {
        return CredentialsActions.loadCredentials();
      })
    )
  );

  fnwHeightChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FnwActions.fnwHeightChange.type),
      withLatestFrom(this.store$.pipe(select(isIframeScrollDown))),
      filter(([action, isScrollDown]) => isScrollDown),
      map(() => {
        this.iframeService.scrollToBottom();
        return IFrameActions.scrollDown();
      })
    )
  );

  fnwLocationUpdate$ = createEffect(
    () => ({
      scheduler = asyncScheduler, // for testing
    } = {}) => {
      return this.actions$.pipe(
        ofType(FnwActions.fnwLocationUpdate.type),
        withLatestFrom(this.store$.pipe(select(isMonolith))),
        debounceTime(0, scheduler), // skip multiple events during the same frame
        map(([action, monolith]) => {
          if (monolith && !action.serviceName) {
            const parsedUrl = parseUrl(action.url);
            const monolithUrl = location.pathname + location.search;
            if (
              this.compareUrlService.isDifferentUrl(action.url, monolithUrl)
            ) {
              this.router.navigate([parsedUrl.url], {
                queryParams: parsedUrl.query,
                replaceUrl: true,
              });
            }
          }
          if (!monolith && action.serviceName) {
            const serviceUrl = /\/[^\/]*(.*)/.exec(
              location.pathname + location.search
            );
            const parsedUrl = parseUrl('/' + action.serviceName + action.url);
            if (
              serviceUrl &&
              this.compareUrlService.isDifferentUrl(action.url, serviceUrl[1])
            ) {
              this.router.navigate([parsedUrl.url], {
                queryParams: parsedUrl.query,
                replaceUrl: true,
              });
            }
          }
        })
      );
    },
    { dispatch: false }
  );
}
