import { NgModule } from '@angular/core';
import {
  FnxLoadingModule,
  FnxFeatureToggleModule,
} from '@farmnet/fnx-components';
import { CommonModule } from '@angular/common';
import { AppComponent } from './app.component';
import { HeaderModule } from '../../components/header/header.module';
import { RouterModule } from '@angular/router';
import { FooterModule } from '../../components/footer/footer.module';
import { HelpModule } from '../../components/help/help.module';
import { IframePageModule } from '../iframe-page/iframe-page.module';
import { CookieBannerModule } from '@farmnet/cookie-banner';
import { ZenloopModule } from '../../components/zenloop/zenloop.module';
import { NewsPopupModule } from '../../components/popup/news-popup/newsPopup.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    FnxLoadingModule,
    HeaderModule,
    FooterModule,
    RouterModule,
    HelpModule,
    IframePageModule,
    FnxFeatureToggleModule,
    CookieBannerModule,
    ZenloopModule,
    NewsPopupModule,
  ],
  exports: [AppComponent],
})
export class AppModule {}
