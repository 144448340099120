import { InjectionToken } from '@angular/core';
import { ResizeObserver } from '@juggle/resize-observer';

export const RESIZE_OBSERVER = new InjectionToken<typeof ResizeObserver>(
  'resize-observer',
  {
    providedIn: 'root',
    factory: () => ResizeObserver,
  }
);
