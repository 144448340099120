<a
  [appTrackNavigation]="{ action: 'Help', label: key }"
  (click)="handleClick()"
  [attr.href]="externalLink"
  [attr.target]="externalLink ? '_blank' : null"
>
  <div
    class="nav-item"
    [ngClass]="{ 'nav-item--active': (currentHelpPage$ | async) === page }"
    *transloco="let t"
  >
    <fnx-icon [iconName]="iconName" [ngClass]="iconName + '-icon'"></fnx-icon>
    <div>{{ t(key) }}</div>
  </div>
</a>
