import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ExternalUrlResolver implements Resolve<void> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const url = route.paramMap.get('url');

    if (url) {
      window.open(url, '_self');
    } else {
      throw new Error(`mandatory route param 'url' missing`);
    }
  }
}
