import { Directive, HostListener, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsActions } from '../actions';

@Directive({
  selector: '[appTrackNavigation]',
})
export class TrackNavigationDirective {
  @Input('appTrackNavigation')
  trackOptions: AnalyticsActions.TrackNavigationPayload;

  constructor(private store: Store<AnalyticsActions.AnalyticsActionsUnion>) {}

  @HostListener('click', ['$event']) onClick() {
    this.store.dispatch(AnalyticsActions.trackNavigation(this.trackOptions));
  }
}
