import { AfterViewChecked, Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

interface ConfigData {
  zenloopActive: boolean;
}

@Component({
  selector: 'app-zenloop',
  template: '<ng-container></ng-container>',
})
export class ZenloopComponent implements OnInit {
  @Input()
  userSettings: any;
  @Input()
  countryCode: any;

  zenloopActive = false;

  surveyLanguages = {
    de: {
      survey:
        'WVRKaU1UUmtZV0V0TWpabE5pMDBaVFZpTFRnMll6VXRaRGM1TnpjNFkyUTVPVEpp',
    },
    en: {
      survey:
        'WlRGbU5qTXhZMkl0WkRZM01TMDBPVFpqTFRneU1qWXRaVGt6WWpZeU5Ua3pNVEJr',
    },
    pl: {
      survey:
        'WWpVeFlUZ3hNRGd0TkdVMk5DMDBORFUyTFRreE9XTXRZekkyWkdWaFlUQTNZakl5',
    },
    fr: {
      survey:
        'TXpVd1pqWm1NelV0TVdZelpTMDBZemd5TFdFNFpHWXRZMkZsTnpNelpqRTNaRGc1',
    },
  };

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http.get<ConfigData>('/assets/config.json').subscribe((data) => {
      this.zenloopActive = data.zenloopActive ?? false;
      this.handleZenloop();
    });
  }

  handleZenloop(): void {
    if (!this.zenloopActive) {
      return;
    }
    this.addScript(this.userSettings.uiLanguage);
  }

  initOverlay(): void {
    // @ts-ignore
    const zenloop = window.Zenloop;
    zenloop.recipient = {
      identity: this.userSettings.personId,
      identity_type: 'custom',
      properties: {
        country: this.countryCode,
        language: this.userSettings.uiLanguage,
      },
    };

    zenloop.state.recipient = {
      ...zenloop.state.recipient,
      ...zenloop.recipient,
    };
    zenloop.initializeSurvey();
    zenloop.showOverlay();
  }

  addScript(lang): void {
    const zenloop = { isVisible: false };
    // @ts-ignore
    window.Zenloop = zenloop;

    document.addEventListener('zl.initialized', () => {
      this.initOverlay();
    });

    const surveyHash = this.surveyLanguages[lang]
      ? this.surveyLanguages[lang].survey
      : this.surveyLanguages.en.survey;

    const script = document.createElement('script');
    script.id = 'zl-website-overlay-loader';
    script.src =
      'https://zenloop-website-overlay-production.s3.amazonaws.com/loader/zenloop.load.min.js?survey=' +
      surveyHash;
    script.async = true;

    document.body.appendChild(script);
  }
}
