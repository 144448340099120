import { createAction, union, props } from '@ngrx/store';
import { FnwProductTrackingParameters } from '@farmnet/webapp-loader-companion';

export const trackVirtualPath = createAction(
  '[Analytics] Track Virtual Path',
  (
    {
      title,
      virtPath,
      isMonolithPath,
    }: { title: string; virtPath: string; isMonolithPath?: boolean } = {
      title: '',
      virtPath: '',
      isMonolithPath: false,
    }
  ) => ({ title, virtPath, isMonolithPath })
);

export interface TrackNavigationPayload {
  action: string;
  label: string;
  value?: number;
}

export const trackNavigation = createAction(
  '[Analytics] Track Navigation',
  props<TrackNavigationPayload>()
);

export const trackProductEvent = createAction(
  '[Analytics] Track Product',
  props<FnwProductTrackingParameters>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  trackVirtualPath,
  trackNavigation,
  trackProductEvent,
});
export type AnalyticsActionsUnion = typeof all;
