import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromCredentials from '../../credentials/reducers';
import {
  getFooterTop,
  getHeaderTop,
  isMonolith,
  isPageNotFound,
  isService,
} from '../../reducers';
import { combineLatest, Observable } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Breakpoints } from '@farmnet/fnx-components';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  templateUrl: './iframe-page.component.html',
  styleUrls: ['./iframe-page.component.scss'],
  selector: 'app-iframe-page',
})
export class IframePageComponent {
  showMonolith$ = this.store.pipe(select(isMonolith));
  showPageNotFound$ = this.store.pipe(select(isPageNotFound));
  showService$ = this.store.pipe(select(isService));

  top$ = this.store.pipe(select(getHeaderTop));
  footerTop$ = this.store.pipe(select(getFooterTop));
  desktopBreakpointChanges$: Observable<
    BreakpointState
  > = this.breakpointObserver.observe([Breakpoints.desktopAndUp]);
  height$ = combineLatest([
    this.top$,
    this.footerTop$,
    this.desktopBreakpointChanges$,
  ]).pipe(
    map(([top, footerTop, desktopBreakpoint]) => {
      if (desktopBreakpoint.matches) {
        top = top ? -top : 0;
        footerTop = footerTop ? -footerTop : 0;

        const height = `calc(100% + ${top}px + ${footerTop}px`;
        return this.domSanitizer.bypassSecurityTrustStyle(height);
      }
      return null;
    })
  );

  constructor(
    private store: Store<fromCredentials.State>,
    private domSanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {}
}
