import { createAction, props, union } from '@ngrx/store';

export const isLeader = createAction('[Broadcast] Is Leader');

export const broadcast = createAction(
  '[Broadcast] Broadcast',
  props<{ action: any }>()
);

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  isLeader,
  broadcast,
});
export type BroadcastActionsUnion = typeof all;
