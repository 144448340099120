import { ModuleInfoActions } from '../actions';

export interface State {
  isDeveloperMode: boolean;
  monolithSegments: string[];
}

export const NotModuleInfoDefaultRoutes = [
  '/admin',
  // to be deprecated
  '/analyse',
  '/booking',
  '/core/',
  '/planning/',
  // end
  '/farm',
  '/crop',
  '/cattle',
  '/livestock',
  '/dairynet',
  '/dashboard',
  '/genericFarmMap',
  '/test-service',
  '/pageNotFound',
  '/components',
];

export const initialState: State = {
  isDeveloperMode: false,
  monolithSegments: [],
};

export function reducer(
  state = initialState,
  action: ModuleInfoActions.Union
): State {
  switch (action.type) {
    case ModuleInfoActions.set.type:
      return {
        isDeveloperMode: action.isDeveloperMode,
        monolithSegments: action.monolithSegments,
      };
    default:
      return state;
  }
}

export const isDeveloperMode = (state: State) => state.isDeveloperMode;
export const getMonolithRoutes = (state: State) => {
  if (state.monolithSegments) {
    return NotModuleInfoDefaultRoutes.concat(
      state.monolithSegments.map((segment) => '/' + segment)
    );
  }
  return [];
};
