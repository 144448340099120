import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromCredentials from './reducers/credentials.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CredentialsEffects } from './effects/credentials.effects';
import { HttpClientModule } from '@angular/common/http';
import { PartnerLogoutUrlEffects } from './effects/partner-logout-url.effects';

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    StoreModule.forFeature('credentials', fromCredentials.reducer),
    EffectsModule.forFeature([CredentialsEffects, PartnerLogoutUrlEffects]),
  ],
})
export class CredentialsModule {}
