import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogoutUrl } from '../models/logout-url';
const AUTH_URL = '/365FarmNet/rest/user';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  constructor(private http: HttpClient) {}

  public logout(): Observable<any> {
    return this.http.post(`${AUTH_URL}/logout`, null);
  }

  public getLogoutUrlForPartner(partnerId: string): Observable<LogoutUrl> {
    return this.http.get<LogoutUrl>(
      `/365FarmNet/mod/partner-service/v1.0/partners/${partnerId}/logout-url`
    );
  }
}
