import { CredentialsActions, PartnerLogoutUrlActions } from '../actions/';
import { Credentials } from '@farmnet/webapp-loader-companion';
import { Right } from '../models/right';

export interface State {
  loading: boolean;
  credentials: Credentials | null;
  partnerLogoutUrl: string | null;
}

export const initialState: State = {
  loading: true,
  credentials: null,
  partnerLogoutUrl: null,
};

export function reducer(
  state = initialState,
  action: CredentialsActions.Union | PartnerLogoutUrlActions.Union
): State {
  switch (action.type) {
    case CredentialsActions.loadCredentials.type:
      return {
        ...state,
      };
    case CredentialsActions.loadCredentialsSuccess.type:
      return {
        ...state,
        loading: false,
        credentials: action.credentials,
      };
    case CredentialsActions.loadCredentialsFailure.type:
      return {
        ...state,
        credentials: null,
        loading: false,
      };
    case PartnerLogoutUrlActions.set.type:
      return {
        ...state,
        partnerLogoutUrl: action.partnerLogoutUrl,
      };
    default:
      return state;
  }
}

export const get = (state: State) => state.credentials;
export const isLoggedIn = (state: State) => {
  return state.credentials && state.credentials.loggedIn;
};

export const isLoading = (state: State) => state.loading;
export const isDemoAccount = (state: State) =>
  state.credentials &&
  state.credentials.userProperties['demo-account'] === 'true';
export const getUserId = (state: State) => {
  return state.credentials && state.credentials.usernameToDisplay;
};
export const getUserName = (state: State) => {
  return (
    state.credentials &&
    state.credentials.person &&
    state.credentials.person.nameString
  );
};
export const getRelease = (state: State) =>
  state.credentials && state.credentials.release;

export const getUserLocale = (state: State) =>
  state.credentials && state.credentials.loggedIn && state.credentials.locale;

export const getCountryCode = (state: State) => {
  return (
    state.credentials &&
    state.credentials.company &&
    state.credentials.company.countryIsoCode
  );
};

export const getCustomerNumber = (state: State) => {
  return (
    state.credentials &&
    state.credentials.company &&
    state.credentials.company.customerNumber
  );
};

export const getPerson = (state: State) => {
  return state.credentials && state.credentials.person;
};

export const getCompany = (state: State) => {
  return state.credentials && state.credentials.company;
};

export const getSettings = (state: State) => {
  return state.credentials && state.credentials.userSettings;
};

export const getPartnerId = (state: State) => {
  return (
    state.credentials &&
    state.credentials.userProperties['com.365farmnet.partner.id']
  );
};

export const isShopCountry = (state: State) => {
  return state.credentials && state.credentials.company.shopCountry;
};

export const getPartnerLogoutUrl = (state: State) => {
  return state.partnerLogoutUrl;
};

export const getRights = (state: State): Right[] => {
  return state.credentials ? state.credentials.rights : [];
};

export const hasRight = (state: State, right: Right): boolean => {
  const rights = getRights(state);

  return rights.includes(right);
};
export const isProductAvailable = (state: State, product: string) => {
  return (
    state.credentials &&
    state.credentials.availableProducts &&
    state.credentials.availableProducts.includes(product)
  );
};

export const hasProduct = (state: State, product: string) => {
  return (
    state.credentials &&
    state.credentials.licensedProducts &&
    state.credentials.licensedProducts.includes(product)
  );
};
