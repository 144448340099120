import { Injectable } from '@angular/core';
import { remove, get } from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class CookieService {
  public remove(name: string) {
    remove(name);
  }
  public get(name: string) {
    return get(name);
  }
}
