import {
  Component,
  AfterViewInit,
  OnDestroy,
  ViewChild,
  Inject,
} from '@angular/core';
import { IframeComponent } from 'src/app/components/iframe/iframe.component';
import { select, Store } from '@ngrx/store';
import { map, filter, withLatestFrom } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  isIframeLoading,
  State,
  getIframeServiceUrl,
  getIframeServiceName,
} from '../../reducers';
import { ResizeObserver } from '@juggle/resize-observer';
import { IFrameService } from '../../services/iframe.service';
import { RESIZE_OBSERVER } from '../../services/resize-observer';

import { IFrameActions } from 'src/app/actions';

@Component({
  templateUrl: './iframe-service-page.component.html',
  styleUrls: ['./iframe-service-page.component.scss'],
  selector: 'app-iframe-service-page',
})
export class IframeServicePageComponent implements AfterViewInit, OnDestroy {
  @ViewChild('appIframe')
  iframe: IframeComponent;

  routeWithServiceName$ = this.store.pipe(
    select(getIframeServiceUrl),
    filter<string>((url) => url !== null && url !== undefined),
    withLatestFrom(this.store.pipe(select(getIframeServiceName)))
  );

  private loadedServiceName: null | string = null;
  route$ = this.routeWithServiceName$.pipe(
    filter(
      ([url, serviceName]) =>
        this.loadedServiceName === null ||
        serviceName !== this.loadedServiceName
    ),
    map(([url, serviceName]) => {
      this.loadedServiceName = serviceName;
      return environment.servicesPrefix + this.getUrlWithFixedSlash(url);
    })
  );
  postMessage$ = this.routeWithServiceName$
    .pipe(
      filter(
        ([url, serviceName]) =>
          this.loadedServiceName !== null &&
          serviceName === this.loadedServiceName
      )
    )
    .subscribe(([url, serviceName]) => {
      this.iframeService.postServiceNavigateInService(url);
    });

  loading$ = this.store.pipe(select(isIframeLoading));
  private resizeObserver: ResizeObserver;

  constructor(
    private store: Store<State>,
    private iframeService: IFrameService,
    @Inject(RESIZE_OBSERVER)
    private ResizeObserverCreator: typeof ResizeObserver
  ) {}

  getUrlWithFixedSlash(userUrl: string) {
    const segments = userUrl.split('/');
    const slash = segments.splice(0, 1)[0];
    const serviceName = segments.splice(0, 1)[0];

    let result = [slash, serviceName, ...segments].join('/');

    if (!segments.length) {
      result = result + '/';
    }

    return result;
  }

  onPageNotFound() {
    this.store.dispatch(IFrameActions.pageNotFound());
  }

  ngAfterViewInit() {
    this.resizeObserver = new this.ResizeObserverCreator(
      (entries, observer) => {
        this.iframe.triggerScroll();
      }
    );
    this.resizeObserver.observe(document.body);
    this.iframeService.setServiceIframe(this.iframe.iframe.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(document.body);
    this.resizeObserver.disconnect();
  }
}
