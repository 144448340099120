import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FnwLocationService } from '@farmnet/webapp-loader-companion';
import { TranslocoService } from '@ngneat/transloco';

export interface WindowLocation {
  origin: string;
  pathname: string;
  href: string;
  search: string;
}

const LOGIN_URL = '/signon/login';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  constructor(private translocoService: TranslocoService) {}

  public getWindowLocation(): WindowLocation {
    return {
      origin: window.location.origin,
      pathname: window.location.pathname,
      href: window.location.href,
      search: window.location.search,
    };
  }

  public getLocalizedLoginUrl(requestedRoute?: string) {
    const parts = LOGIN_URL.split('/');
    const localeParts = this.translocoService.getActiveLang().split('-');

    let loginUrl = `/${parts[1]}/${localeParts[0]}/${parts[2]}`;

    if (requestedRoute) {
      loginUrl = FnwLocationService.createUrl(loginUrl, {
        requestedRoute,
      });
    }

    return loginUrl;
  }
}
