<ng-container *transloco="let t">
  <fnx-alert
    class="test-browser-warning"
    [alertIsClosable]="true"
    alertMessageLevel="warning"
    *ngIf="!browserWarningService.isBrowserSupported"
  >
    {{ t('browserWarningText') }}
  </fnx-alert>
</ng-container>
