import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';

import { IframePageComponent } from '../container/iframe-page/iframe-page.component';
import { IFrameService } from './iframe.service';
import { Actions, ofType } from '@ngrx/effects';
import {
  fnwAllowNavigateInService,
  fnwRejectNavigateInService,
} from '../actions/fnw.actions';
import { map, take } from 'rxjs/operators';
import { FnwActions } from '../actions';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class IframeGuard implements CanDeactivate<IframePageComponent> {
  constructor(
    private iframeService: IFrameService,
    private actions: Actions<FnwActions.FnwActionsUnion>,
    private router: Router,
    private store: Store<fromRoot.State>
  ) {}

  isPageNotFound(): boolean {
    let isPageNotFound = true;
    this.store
      .pipe(select(fromRoot.isPageNotFound), take(1))
      .subscribe((pageNotFound) => {
        isPageNotFound = pageNotFound;
      });

    return isPageNotFound;
  }

  isLoading(): boolean {
    let isLoading = false;
    this.store
      .pipe(select(fromRoot.isIframeLoading), take(1))
      .subscribe((loading) => {
        isLoading = loading;
      });

    return isLoading;
  }

  canDeactivate(
    component: IframePageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (nextState && nextState.url.startsWith('/external')) {
      return true;
    }

    const navigation = this.router.getCurrentNavigation();
    const toService =
      navigation &&
      navigation.extras.state &&
      navigation.extras.state.toService;

    if (
      !this.isPageNotFound() &&
      !this.isLoading() &&
      !toService &&
      nextState &&
      !this.iframeService.isCurrentUrl(nextState.url)
    ) {
      this.iframeService.postLocationChange(nextState.url);
      // TODO:  this sends an extra navigation event to the monolith
      // When pressing Back button (from angularjs or angular) it will initiate a new navigation

      return this.actions.pipe(
        ofType(fnwAllowNavigateInService.type, fnwRejectNavigateInService.type),
        map((action) => {
          switch (action.type) {
            case fnwAllowNavigateInService.type:
              return true;
            case fnwRejectNavigateInService.type:
              return false;
          }
          return true;
        })
      );
    }
    return true;
  }
}
