import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

const MODULE_INFO_ENDPOINT = '/365FarmNet/mod/mms/moduleinfo';

export interface ModuleInfo {
  isDeveloperMode: boolean;
  monolithSegments: string[];
}

@Injectable({
  providedIn: 'root',
})
export class ModuleInfoService {
  constructor(private http: HttpClient) {}

  get(): Observable<ModuleInfo> {
    return this.http.get(MODULE_INFO_ENDPOINT).pipe(
      map((moduleInfos: any[]) => {
        const connectModule =
          moduleInfos &&
          moduleInfos.filter((mod) => mod.name.toLowerCase() === 'connect')[0];
        const isDeveloperMode =
          connectModule && connectModule.farmnetMyConnectVisible;
        const monolithSegments = moduleInfos.map((mod) =>
          mod.name.toLowerCase()
        );
        return { isDeveloperMode, monolithSegments };
      }),
      catchError(() => of({ isDeveloperMode: false, monolithSegments: [] }))
    );
  }
}
