import { Injectable, NgZone } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { ModuleInfoActions } from '../actions';
import { switchMap, map, observeOn } from 'rxjs/operators';
import { ModuleInfoService } from '../services/module-info.service';
import { Action } from '@ngrx/store';
import { leaveZone, enterZone } from '../services/leave-zone-scheduler';
import { asyncScheduler, Observable, interval } from 'rxjs';

@Injectable()
export class ModuleInfoEffects implements OnInitEffects {
  constructor(
    private actions$: Actions,
    private moduleInfoService: ModuleInfoService,
    private ngZone: NgZone
  ) {}

  get$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ModuleInfoActions.get.type),
      switchMap(() => this.moduleInfoService.get()),
      map(({ isDeveloperMode, monolithSegments }) =>
        ModuleInfoActions.set({ isDeveloperMode, monolithSegments })
      )
    )
  );

  keepAlive$ = createEffect(
    () => ({
      interval: inter = 10 * 60 * 1000,
      scheduler = leaveZone(this.ngZone, asyncScheduler), // leave angular zone, to not block protractor
    } = {}): Observable<Action> => {
      return interval(inter, scheduler).pipe(
        observeOn(enterZone(this.ngZone, scheduler)),
        map(() => ModuleInfoActions.get())
      );
    }
  );

  ngrxOnInitEffects(): Action {
    return ModuleInfoActions.get();
  }
}
