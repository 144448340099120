import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BroadcastActions } from '../actions';
import { map, tap } from 'rxjs/operators';
import { BroadcastService } from '../services/broadcast.service';

@Injectable()
export class BroadcastEffects {
  constructor(
    private actions$: Actions<BroadcastActions.BroadcastActionsUnion>,
    private broadcastService: BroadcastService
  ) {}

  lead$ = createEffect(() =>
    this.broadcastService
      .awaitLeadership()
      .pipe(map(() => BroadcastActions.isLeader()))
  );

  broadcast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BroadcastActions.broadcast.type),
        tap((action) => {
          this.broadcastService.broadcast(action);
        })
      ),
    {
      dispatch: false,
    }
  );

  messages$ = createEffect(() =>
    this.broadcastService.messages().pipe(
      ofType(BroadcastActions.broadcast.type),
      map((action) => {
        return action.action;
      })
    )
  );
}
