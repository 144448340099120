<ng-container [ngSwitch]="credentialsLoading$ | async">
  <fng-cookie-banner
    [cookieBannerLanguage]="userLocale$ | async"
  ></fng-cookie-banner>
  <fnx-loading *ngSwitchCase="true" class="app-fnx-loading"></fnx-loading>

  <ng-container *ngSwitchCase="false">
    <app-header
      [userId]="userId$ | async"
      [userName]="userName$ | async"
      [isDemoAccount]="isDemoAccount$ | async"
      [developSectionEnabled]="isDeveloperMode$ | async"
      [showLiveStock]="(countryCode$ | async) === 'CHE'"
      [showCattle]="(countryCode$ | async) !== 'CHE'"
      [section]="section$ | async"
      [style.top.px]="headerTop$ | async"
      [showShop]="showShop$ | async"
      (logout)="logout()"
      (help)="onHelp()"
      (height)="emitHeaderHeight($event)"
    ></app-header>

    <main>
      <router-outlet></router-outlet>
    </main>

    <app-footer
      [style.bottom.px]="footerTop$ | async"
      (height)="emitFooterHeight($event)"
      [release]="release$ | async"
    ></app-footer>
    <app-zenloop
      [userSettings]="userSettings$ | async"
      [countryCode]="countryCode$ | async"
    ></app-zenloop>
    <app-news-popup
      [userSettings]="userSettings$ | async"
      [countryCode]="countryCode$ | async"
    ></app-news-popup>
  </ng-container>
</ng-container>

<app-help
  [isOpen]="isHelpOpen$ | async"
  [userLocale]="userLocale$ | async"
  [url]="url$ | async"
  (close)="closeHelp()"
></app-help>
