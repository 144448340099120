import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { HelpPage } from '../../../reducers/help.reducer';
import { Store } from '@ngrx/store';
import * as fromCredentials from '../../../credentials/reducers';
import { detect } from 'detect-browser';
import { combineLatest, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-help-navigation',
  templateUrl: './help-navigation.component.html',
  styleUrls: ['./help-navigation.component.scss'],
})
export class HelpNavigationComponent implements OnInit, OnChanges {
  HelpPage = HelpPage;
  @Input()
  lang;
  @Input()
  url;

  _countryCode;
  _lang;
  _contactParams = new HttpParams();

  infoIFrameUrl$: Observable<string>;
  contactIFrameUrl$: Observable<string>;
  teamviewerIFrameUrl$: Observable<string>;

  constructor(private credentialStore: Store<fromCredentials.State>) {}

  ngOnInit() {
    this.credentialStore
      .select(fromCredentials.getCountryCode)
      .subscribe((code) => {
        this._countryCode = code;
      });
    this._contactParams = new HttpParams();
    const personDetails$ = this.credentialStore.select(
      fromCredentials.getPerson
    );
    const companyDetails$ = this.credentialStore.select(
      fromCredentials.getCompany
    );
    const settingsDetails$ = this.credentialStore.select(
      fromCredentials.getSettings
    );
    combineLatest([
      personDetails$,
      companyDetails$,
      settingsDetails$,
    ]).subscribe(([pD, cD, sD]) => {
      this.setCredentialContactParams(pD, cD, sD);
      this.setContactUrl();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this._lang = this.lang ? this.lang.split('-')[0] : 'de';
    this.updateIFrameUrls();
  }

  updateIFrameUrls() {
    this.setInfoIFrameUrl();
    this.setContactUrl();
    this.setTeamviewerUrl();
  }

  setInfoIFrameUrl() {
    this.infoIFrameUrl$ = of(
      `//portal-help.365farmnet.com/wp-json/365farmnet-help/api/v2/${this.lang}${this.url}`
    );
  }

  setContactUrl() {
    this.setInputContactParams();
    this.contactIFrameUrl$ = of(
      `https://dmza5jrge9i3w.cloudfront.net/?${this._contactParams.toString()}`
    );
  }

  setTeamviewerUrl() {
    this.teamviewerIFrameUrl$ = of(
      `https://dmza5jrge9i3w.cloudfront.net/remote-maintenance?` +
        `language=${this._lang}&country=${this._countryCode}`
    );
  }

  setInputContactParams() {
    this._contactParams = this._contactParams.set(
      'url',
      encodeURIComponent(this.url)
    );
    this._contactParams = this._contactParams.set('language', this._lang);
    const browser = detect(window.navigator.userAgent);
    if (browser) {
      const browserInfo = `${browser.name}_${browser.version}_${browser.os}`;
      this._contactParams = this._contactParams.set('browser', browserInfo);
    }
  }

  setCredentialContactParams(personDetails, companyDetails, settingsDetails) {
    if (personDetails) {
      this._contactParams = this._contactParams.set(
        'name',
        personDetails.nameString
      );
      this._contactParams = this._contactParams.set(
        'user_name',
        personDetails.userId.toUpperCase()
      );
      if (settingsDetails.contactEmail)
        this._contactParams = this._contactParams.set(
          'mail',
          settingsDetails.contactEmail
        );
      if (personDetails.tel)
        this._contactParams = this._contactParams.set(
          'phone',
          personDetails.tel
        );
    }
    if (companyDetails) {
      this._contactParams = this._contactParams.set(
        'customer_no',
        companyDetails.customerNumber
      );
      this._contactParams = this._contactParams.set(
        'country',
        companyDetails.countryIsoCode
      );
    }
  }
}
