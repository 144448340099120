<div class="message test-page-not-found">
  <img [src]="LOGO" alt="logo" />

  <h2 i18n="pageNotFound|heading@@pageNotFoundHeading">
    Es ist ein Problem aufgetreten
  </h2>

  <div i18n="pageNotFound|message@@pageNotFoundMessage">
    Die Seite wurde leider nicht gefunden
  </div>
</div>
