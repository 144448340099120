import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpComponent } from './help.component';
import { FnxIconModule, FnxPopupModule } from '@farmnet/fnx-components';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { HelpNavigationComponent } from './help-navigation/help-navigation.component';
import { HelpNavigationItemComponent } from './help-navigation/help-navigation-item/help-navigation-item.component';
import { TrackNavigationModule } from '../../analytics/directives/track-navigation.module';
import { StoreModule } from '@ngrx/store';
import * as fromCredentials from '../../credentials/reducers/credentials.reducer';
import * as fromHelp from '../../reducers/help.reducer';

@NgModule({
  declarations: [
    HelpComponent,
    HelpNavigationComponent,
    HelpNavigationItemComponent,
  ],
  imports: [
    CommonModule,
    StoreModule.forRoot({
      credentials: fromCredentials.reducer,
      help: fromHelp.reducer,
    }),
    FnxPopupModule,
    FnxIconModule,
    TranslocoModule,
    TrackNavigationModule,
    RouterModule,
  ],
  exports: [HelpComponent],
})
export class HelpModule {}
