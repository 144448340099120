import { createAction, props, union } from '@ngrx/store';
import { Credentials } from '@farmnet/webapp-loader-companion';

export const loadCredentials = createAction('[Credentials] Load');

export const loadCredentialsSuccess = createAction(
  '[Credentials] Load Success',
  props<{ credentials: Credentials }>()
);

export const loadCredentialsFailure = createAction(
  '[Credentials] Load Failure',
  props<{ error: Error }>()
);

export const redirectToLogin = createAction(
  '[Credentials] redirect to login',
  ({ requestedRoute }: { requestedRoute?: string } = {}) => ({ requestedRoute })
);

export const applyNewLocale = createAction(
  '[Credentials] redirect to localized url',
  props<{ locale: string }>()
);

export const logout = createAction('[Credentials] logout ');
export const logoutSuccess = createAction('[Credentials] logout success');

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  loadCredentials,
  loadCredentialsSuccess,
  loadCredentialsFailure,
  redirectToLogin,
  redirectToLocalizedUrl: applyNewLocale,
  logout,
  logoutSuccess,
});
export type Union = typeof all;
