<div class="nav">
  <app-help-navigation-item
    [page]="HelpPage.INFO"
    [key]="'helpInfoLink'"
    [iconName]="'help-circle-outline'"
    [iframeSrc]="infoIFrameUrl$ | async"
  ></app-help-navigation-item>
  <app-help-navigation-item
    [page]="HelpPage.CONTACT"
    [key]="'helpContactLink'"
    [iconName]="'mail-sent-outline'"
    [iframeSrc]="contactIFrameUrl$ | async"
  ></app-help-navigation-item>
  <!--  <app-help-navigation-item-->
  <!--    [page]="HelpPage.DATE"-->
  <!--    [key]="'helpDateLink'"-->
  <!--    [iconName]="'week-clock-outline'"-->
  <!--  ></app-help-navigation-item>-->
  <app-help-navigation-item
    [page]="HelpPage.REMOTEMAINTENANCE"
    [key]="'helpRemoteMaintenanceLink'"
    [iconName]="'laptop-outline'"
    [iframeSrc]="teamviewerIFrameUrl$ | async"
  ></app-help-navigation-item>
</div>
