import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { WebappLoaderRoutingModule } from './webapp-loader-routing.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers, State } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import {
  StoreRouterConnectingModule,
  DefaultRouterStateSerializer,
  RouterState,
} from '@ngrx/router-store';
import { CredentialsModule } from './credentials/credentials.module';
import { FnwEffects, BroadcastEffects, ModuleInfoEffects } from './effects';
import { EmptyModule } from './components/empty/empty.module';
import { AppModule } from './container/app/app.module';
import { IframeMonolithPageModule } from './container/iframe-monolith-page/iframe-monolith-page.module';
import { AppComponent } from './container/app/app.component';
import { AnalyticsModule } from './analytics/analytics.module';
import { IframeServicePageModule } from './container/iframe-service-page/iframe-service-page.module';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { IframeExternalUrlWrapperModule } from './container/iframe-external-url-wrapper/iframe-external-url-wrapper.module';

declare const require; // Use the require method provided by webpack

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    WebappLoaderRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false, // TODO: change to true after https://github.com/ngrx/platform/issues/2404
        strictActionImmutability: false, // TODO: change to true after https://github.com/ngrx/platform/issues/2404
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'webapp-loader',
    }),
    EffectsModule.forRoot([FnwEffects, BroadcastEffects, ModuleInfoEffects]),
    StoreRouterConnectingModule.forRoot({
      serializer: DefaultRouterStateSerializer,
      routerState: RouterState.Minimal, // TODO: consider removing it after https://github.com/ngrx/platform/issues/2404
    }),
    CredentialsModule,
    AnalyticsModule,
    IframeMonolithPageModule,
    IframeServicePageModule,
    IframeExternalUrlWrapperModule,
    EmptyModule,
    AppModule,
    HttpClientModule,
    TranslocoRootModule,
  ],
  bootstrap: [AppComponent],
})
export class WebappLoaderModule {}
