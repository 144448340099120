import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import { isDifferentUrl } from 'src/app/helpers/compare-url';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss'],
})
export class IframeComponent {
  @ViewChild('iframe', { static: true })
  iframe: ElementRef<HTMLIFrameElement>;

  @Input()
  set route(url: string) {
    const contentWindow = this.iframe.nativeElement.contentWindow;
    if (!contentWindow) {
      return;
    }
    if (url && isDifferentUrl(contentWindow.location.toString(), url)) {
      this.changeIframeLocation(url);
    }
  }

  @Input()
  loading: boolean = true;

  @Output()
  pageNotFound = new EventEmitter<void>();

  private changeIframeLocation(url: string) {
    const contentWindow = this.iframe.nativeElement.contentWindow;
    if (!contentWindow) {
      return;
    }

    contentWindow.location.replace(url);
  }

  triggerScroll() {
    const contentWindow = this.iframe.nativeElement.contentWindow;
    if (!contentWindow) {
      return;
    }
    // force scroll event (adjust header/footer)
    let scrollEvent;
    const isIE11 =
      !!(window as any).MSInputMethodContext &&
      !!(document as any).documentMode;
    if (!isIE11) {
      scrollEvent = new Event('scroll');
    } else {
      scrollEvent = document.createEvent('Event');
      scrollEvent.initEvent('scroll', true, true);
    }
    contentWindow.dispatchEvent(scrollEvent);
  }

  onLoad($event: Event) {
    const iframe = $event.target as HTMLIFrameElement;
    if (iframe.contentDocument) {
      const farmnetError = iframe.contentDocument.querySelector<
        HTMLMetaElement
      >('meta[name="farmnet:error"]');
      if (farmnetError && farmnetError.content === '404') {
        this.pageNotFound.emit();
      }
    }
  }
}
