import { Injectable } from '@angular/core';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { fromEvent, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { BroadcastActions } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class BroadcastService {
  private channel = new BroadcastChannel<
    BroadcastActions.BroadcastActionsUnion
  >('webapp-loader');
  private elector = createLeaderElection(this.channel);

  awaitLeadership(): Observable<void> {
    return fromPromise(this.elector.awaitLeadership());
  }

  broadcast(message: BroadcastActions.BroadcastActionsUnion) {
    this.channel.postMessage(message);
  }

  messages(): Observable<BroadcastActions.BroadcastActionsUnion> {
    return fromEvent(this.channel, 'message');
  }
}
