import { Inject, Injectable } from '@angular/core';
import { TrackNavigationPayload } from '../actions/analytics.actions';
import { FnwProductTrackingParameters } from '@farmnet/webapp-loader-companion';
import { TAG_MANAGER } from './tag-manager';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(@Inject(TAG_MANAGER) private tagManager: any) {}

  private static invalidTrackOptions(
    trackOptions: TrackNavigationPayload
  ): boolean {
    return !(trackOptions.action && trackOptions.label);
  }

  trackNavigation(trackOptions: TrackNavigationPayload) {
    if (AnalyticsService.invalidTrackOptions(trackOptions)) {
      console.warn(
        '[Track Navigation] was not possible for values',
        trackOptions
      );
      return;
    }

    this.tagManager.trackEvent(
      'Navigation',
      trackOptions.action,
      trackOptions.label,
      trackOptions.value
    );
  }

  trackVirtualPath({ title, virtPath, isMonolithPath = false }) {
    this.tagManager.trackVirtualPath(title, virtPath, isMonolithPath);
  }

  trackProductEvent(payload: FnwProductTrackingParameters) {
    switch (payload.category) {
      case 'INTERACTION':
        this.tagManager.trackProductInteractionEvent(
          payload.action,
          payload.label,
          payload.value
        );

        break;
      case 'POP_UP':
        this.tagManager.trackProductPopupEvent(
          payload.action,
          payload.label,
          payload.value
        );
        break;
    }
  }
}
