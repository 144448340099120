<ng-container *transloco="let t">
  <footer>
    365FarmNet
    {{ t('footerVersion') }}
    <span class="footer-release"> {{ release }}</span> -
    {{ t('footerCopyright') }}
    2013 - <span class="footer-year">{{ currentYear }}</span
    >. {{ t('footerCopyrightRechte') }}.
    <a
      class="test-terms"
      [appTrackNavigation]="{
        action: 'Footer',
        label: 'Terms of Use and Privacy Policy'
      }"
      [href]="t('footerTermsLinkHref')"
      target="_blank"
      fnx-link
      >{{ t('footerTermsLink') }}</a
    >.
    <a
      class="test-impressum"
      [appTrackNavigation]="{ action: 'Footer', label: 'Legal Notice' }"
      [href]="t('footerImpressumLinkHref')"
      target="_blank"
      fnx-link
      >{{ t('footerImpressumLink') }}</a
    >
  </footer>
</ng-container>
