import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewsPopupComponent } from './newsPopup.component';
import {
  FnxButtonModule,
  FnxFeatureToggleModule,
  FnxIconModule,
  FnxPopupModule,
} from '@farmnet/fnx-components';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterModule } from '@angular/router';
import { TrackNavigationModule } from 'src/app/analytics/directives/track-navigation.module';

@NgModule({
  declarations: [NewsPopupComponent],
  imports: [
    CommonModule,
    FnxButtonModule,
    FnxPopupModule,
    FnxIconModule,
    FnxFeatureToggleModule,
    TranslocoModule,
    TrackNavigationModule,
    RouterModule,
  ],
  providers: [],
  exports: [NewsPopupComponent],
})
export class NewsPopupModule {}
