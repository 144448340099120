import { createAction, props, union } from '@ngrx/store';

export const headerHeightChange = createAction(
  '[Iframe] Header Height Change',
  props<{ height: number }>()
);

export const footerHeightChange = createAction(
  '[Iframe] Footer Height Change',
  props<{ height: number }>()
);

export const scrollDown = createAction('[Iframe] Scroll Down');

export const pageNotFound = createAction('[Iframe] Page not found');

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  headerHeightChange,
  footerHeightChange,
  scrollDown,
  pageNotFound,
});
export type Union = typeof all;
