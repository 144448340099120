import { FnwActions, IFrameActions, ModuleInfoActions } from '../actions/';
import {
  ROUTER_CANCEL,
  ROUTER_ERROR,
  ROUTER_NAVIGATED,
  ROUTER_REQUEST,
  RouterCancelAction,
  RouterErrorAction,
  RouterNavigatedAction,
  RouterRequestAction,
} from '@ngrx/router-store';
import { ScrollActionPayload } from '../actions/fnw.actions';
import { isSameUrl } from '../helpers/compare-url';
import {
  getMonolithRoutes,
  NotModuleInfoDefaultRoutes,
} from './module-info.reducer';
import type { FnwSection } from '@farmnet/webapp-loader-companion';

export type Status = 'monolith' | 'service' | 'pageNotFound';

export type Direction = 'UP' | 'DOWN';

export interface State {
  initialLoad: boolean;
  status: Status;
  loading: boolean;
  serviceUrl: string | null;
  serviceName: string | null;
  url: string | null;
  section: FnwSection | null;
  headerHeight: number;
  footerHeight: number;
  headerTop: number;
  footerTop: number;
  onBottom: boolean;
  scrollDown: boolean;
  scrollY: number;
  isNavigating: boolean;
  scrollDirection: Direction;
  monolithRoutes: string[];
}

export const initialState: State = {
  initialLoad: true,
  status: 'monolith',
  loading: true,
  serviceUrl: null,
  serviceName: null,
  url: null,
  section: null,
  headerHeight: 0,
  footerHeight: 0,
  headerTop: 0,
  footerTop: 0,
  onBottom: false,
  scrollDown: false,
  scrollY: 0,
  isNavigating: false,
  scrollDirection: 'DOWN',
  monolithRoutes: NotModuleInfoDefaultRoutes,
};

export function reducer(
  state = initialState,
  action:
    | FnwActions.FnwActionsUnion
    | RouterNavigatedAction
    | RouterRequestAction
    | RouterCancelAction<unknown>
    | RouterErrorAction<unknown>
    | IFrameActions.Union
    | ModuleInfoActions.Union
): State {
  switch (action.type) {
    case FnwActions.fnwLocationUpdate.type:
      return {
        ...state,
        initialLoad: false,
        loading: false,
        section: getMappedSection(action.section),
      };
    case FnwActions.fnwScrollChange.type: {
      const newState = scrollChange(action, state);

      return {
        ...state,
        ...newState,
        scrollDown: false,
      };
    }
    case FnwActions.fnwHeightChange.type: {
      const newState = scrollChange(action, state);
      const scrollDown = state.onBottom && newState.scrollDirection === 'DOWN';

      return {
        ...state,
        ...newState,
        footerTop: scrollDown ? 0 : newState.footerTop,
        scrollDown,
      };
    }
    case FnwActions.fnwSectionChange.type:
      return {
        ...state,
        section: getMappedSection(action.section),
      };
    case ROUTER_REQUEST:
      return {
        ...state,
        isNavigating: true,
      };
    case ROUTER_CANCEL || ROUTER_ERROR:
      return {
        ...state,
        isNavigating: false,
      };
    case ROUTER_NAVIGATED:
      const status: Status = isMonolithRoute(
        state,
        action.payload.routerState.url
      )
        ? 'monolith'
        : 'service';
      const newUrl = action.payload.routerState.url;
      let loading = true;

      if (!state.initialLoad) {
        const changeFromServiceToMonolith = state.status !== status;

        if (changeFromServiceToMonolith) {
          if (status === 'monolith') {
            loading = !isSameUrl(state.url || '', newUrl);
          } else {
            loading = !isSameUrl(state.serviceUrl || '', newUrl);
          }
        } else {
          loading = false;
        }
      }

      let newServiceUrl: string | null = state.serviceUrl;
      let newMonolithUrl: string | null = state.url;
      let newServiceName: string | null = state.serviceName;
      if (status === 'monolith') {
        newMonolithUrl = newUrl;
      } else {
        newServiceUrl = newUrl;
      }

      if (newServiceUrl) {
        newServiceName = newServiceUrl.split('/')[1];
      }
      return {
        ...state,
        onBottom: false,
        scrollDown: false,
        status,
        url: newMonolithUrl,
        loading,
        serviceName: newServiceName,
        serviceUrl: newServiceUrl,
        isNavigating: false,
      };

    case IFrameActions.pageNotFound.type:
      return {
        ...state,
        loading: false,
        status: 'pageNotFound',
        serviceName: null,
      };
    case IFrameActions.scrollDown.type:
      return {
        ...state,
        scrollDown: false,
      };
    case IFrameActions.headerHeightChange.type:
      return {
        ...state,
        headerHeight: action.height,
      };
    case IFrameActions.footerHeightChange.type:
      return {
        ...state,
        footerHeight: action.height,
      };
    case ModuleInfoActions.set.type:
      return {
        ...state,
        monolithRoutes: getMonolithRoutes(action),
      };
    default:
      return state;
  }
}

const webappLoaderSections = {
  admin: 'admin',
  analyse: 'analyse',
  booking: 'booking',
  calendar: 'calendar',
  cattle: 'cattle',
  core: 'core',
  crop: 'crop',
  dashboard: 'dashboard',
  developer: 'developer',
  farm: 'farm',
  farmMap: 'map',
  livestock: 'livestock',
  map: 'map',
  myFarmMap: 'map',
  planning: 'planning',
  shop: 'shop',
  terms: null,
};

function getMappedSection(section: string | null): FnwSection | null {
  if (!section) {
    return null;
  }

  const mappedSection = webappLoaderSections[section];
  // it maps a section comming from iFrame to a valid webapp-loader section
  if (mappedSection === undefined) {
    // tslint:disable-next-line: no-console
    console.debug(
      `[webapp-loader] Section ${mappedSection} could not be mapped`
    );
  }
  return mappedSection || null;
}
function scrollChange(
  action: ScrollActionPayload,
  state: State
): {
  scrollY: number;
  scrollDirection: Direction;
  headerTop: number;
  footerTop: number;
  onBottom: boolean;
} {
  const direction = getDirection(action, state);
  const topReached = isOnTop(action);
  const bottomReached = isPageOnBottom(action, state, direction);
  const pageTooSmallHeader = isPageTooSmallHeader(action, state);
  const pageTooSmallFooter = isPageTooSmallFooter(action, state);

  return {
    scrollY: action.scrollY,
    scrollDirection: direction,
    headerTop: topReached || pageTooSmallHeader ? 0 : -state.headerHeight,
    footerTop: bottomReached || pageTooSmallFooter ? 0 : -state.footerHeight,
    onBottom: !pageTooSmallHeader && !pageTooSmallFooter && bottomReached,
  };
}

function getDirection(
  {
    scrollY,
  }: {
    scrollY: number;
  },
  {
    scrollY: oldScrollY,
    scrollDirection,
  }: {
    scrollY: number;
    scrollDirection: Direction;
  }
): Direction {
  if (scrollY === oldScrollY) {
    // unchanged keep old direction
    return scrollDirection;
  }

  return scrollY < oldScrollY ? 'UP' : 'DOWN';
}

function isOnTop({ scrollY }: { scrollY: number }): boolean {
  return scrollY === 0;
}

function isPageOnBottom(
  { scrollY, scrollMaxY }: ScrollActionPayload,
  { footerHeight, footerTop }: { footerHeight: number; footerTop: number },
  direction: Direction
): boolean {
  let onBottom = scrollY >= scrollMaxY;
  const isFooterShown = footerTop === 0;

  if (isFooterShown) {
    onBottom = scrollMaxY - scrollY <= footerHeight;
  }

  return onBottom;
}

function isPageTooSmallHeader(
  {
    scrollMaxY,
  }: {
    scrollMaxY: number;
  },
  {
    headerHeight,
    headerTop,
  }: {
    headerHeight: number;
    headerTop: number;
  }
): boolean {
  const isHeaderShown = headerTop === 0;

  return scrollMaxY <= headerHeight && isHeaderShown;
}

function isPageTooSmallFooter(
  {
    scrollMaxY,
  }: {
    scrollMaxY: number;
  },
  {
    footerHeight,
    footerTop,
  }: {
    footerHeight: number;
    footerTop: number;
  }
): boolean {
  const isFooterShown = footerTop === 0;

  return scrollMaxY <= footerHeight && isFooterShown;
}

export const isMonolith = (state: State) => state.status === 'monolith';
export const isPageNotFound = (state: State) => state.status === 'pageNotFound';
export const isService = (state: State) => state.status === 'service';
export const isNavigating = (state: State) => state.isNavigating;
export const isLoading = (state: State) => state.loading;
export const isOnBottom = (state: State) => state.onBottom;
export const isScrollDown = (state: State) => state.scrollDown;
export const getScrollDirection = (state: State): Direction =>
  state.scrollDirection;
export const getUrl = (state: State) => state.url;
export const getServiceUrl = (state: State) => state.serviceUrl;
export const getServiceName = (state: State) => state.serviceName;
export const getSection = (state: State) => state.section;
export const getHeaderTop = (state: State) => state.headerTop;
export const getFooterTop = (state: State) => state.footerTop;

export const isMonolithRoute = (state: State, route: string): boolean => {
  return (
    !!state.monolithRoutes.find((segment) => route.startsWith(segment)) ||
    route === '/'
  );
};
