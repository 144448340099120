import { createAction, props, union } from '@ngrx/store';
import { HelpPage } from '../reducers/help.reducer';

export const open = createAction('[Help] Open');
export const toggle = createAction('[Help] Toggle');
export const close = createAction('[Help] Close');

export const changePage = createAction(
  '[HELP-NAVIGATION] Change Page',
  props<{ page: HelpPage; currentIFrameSrc: string }>()
);
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
const all = union({
  openHelp: open,
  toggleHelp: toggle,
  closeHelp: close,
  changePage,
});
export type HelpActionsUnion = typeof all;
