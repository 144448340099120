import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FeatureToggleService } from '@farmnet/fnx-components';

const LOCALSTORAGE_KEY = '365farmnet.news.claas';
const FEATURETOGGLE = 'hide-news-365-becomes-claas';
@Component({
  selector: 'app-news-popup',
  templateUrl: './newsPopup.component.html',
  styleUrls: ['./newsPopup.component.scss'],
})
export class NewsPopupComponent implements OnInit {
  @Input()
  userSettings: any;

  @Input()
  countryCode: any;

  urls = {
    de:
      'https://www.claas.com/de-de/presse/pressemitteilungen/2023-11-12-de-de-connect',
    en: 'https://www.claas.com/en-gb/press/press-releases/2023-11-12-connect',
    pl:
      'https://www.claas.com/pl-pl/prasa/komunikaty-prasowe/2023-11-12-connect',
    fr:
      'https://www.claas.com/fr-fr/presse/communiques-de-presse/2023-11-12-connect',
  };

  close = new EventEmitter();

  url: string = this.urls.en;
  checked: boolean = false;
  open: boolean = false;

  checkFn = (e) => {
    this.checked = e.target.checked;
  };

  getUrl = () => {
    return this.urls[this.userSettings?.uiLanguage] ?? this.urls.en;
  };

  ngOnInit(): void {
    this.close.subscribe(() => {
      window.localStorage.setItem(LOCALSTORAGE_KEY, String(this.checked));
    });

    const localStorageValue = window.localStorage.getItem(LOCALSTORAGE_KEY);

    if (
      this.countryCode === 'CHE' ||
      this.featureToggleService.isFeatureActivated(FEATURETOGGLE)
    ) {
      this.open = false;
    } else if (localStorageValue != null) {
      this.open = localStorageValue.toLowerCase() !== 'true';
    } else {
      this.open = true;
    }

    this.url = this.getUrl();
  }

  constructor(private featureToggleService: FeatureToggleService) {}
}
