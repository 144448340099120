<ng-container *transloco="let t">
  <fnx-popup
    [popupHeader]="t('newsPopupHeader')"
    [popupIsClosable]="true"
    [popupIsDraggable]="false"
    [popupIsMobileCoverAll]="true"
    [popupIsVisible]="open === true"
    popupPosition="center"
    (popupClosed)="close.emit()"
  >
    <ng-container fnx-popup-content>
      <div class="content">
        <div>
          <strong>{{ t('newsPopupTitle') }}</strong>
        </div>
        <div class="image"></div>
        <div>
          {{ t('newsPopupBody1') }}
          <br /><br />
          {{ t('newsPopupBody2') }}
        </div>

        <div class="captionBlock">
          <input #checkbox type="checkbox" (change)="checkFn($event)" />
          <div class="caption">{{ t('newsPopupCaption') }}</div>
        </div>
        <div class="moreInfo">
          <a [href]="url" target="_blank" fnx-button buttonType="primary">
            {{ t('newsPopupMoreInfo') }}
          </a>
        </div>
      </div>
    </ng-container>
  </fnx-popup>
</ng-container>
