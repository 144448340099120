import {
  Component,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { Breakpoints } from '@farmnet/fnx-components';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements AfterViewInit, OnDestroy {
  @Input()
  release: string | null;
  @Output()
  height = new EventEmitter<number>();

  currentYear = new Date().getFullYear();

  constructor(
    private ref: ElementRef<Element>,
    public breakpointObserver: BreakpointObserver
  ) {}

  ngAfterViewInit() {
    this.breakpointObserver
      .observe([
        Breakpoints.tabletAndUp,
        Breakpoints.onlyMobile,
        Breakpoints.desktopAndUp,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          const element = this.ref.nativeElement;
          this.height.emit(element.scrollHeight);
        }
      });
  }

  ngOnDestroy() {
    this.breakpointObserver.ngOnDestroy();
  }
}
