import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as fromCredentials from '../../../../credentials/reducers';
import { Observable } from 'rxjs';
import { currentHelpPage } from '../../../../reducers';
import { changePage } from '../../../../actions/help.actions';
import { select, Store } from '@ngrx/store';
import { HelpPage } from '../../../../reducers/help.reducer';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-help-navigation-item',
  templateUrl: './help-navigation-item.component.html',
  styleUrls: ['./help-navigation-item.component.scss'],
})
export class HelpNavigationItemComponent implements OnInit, OnChanges {
  @Input()
  key;
  @Input()
  page: HelpPage;
  @Input()
  iconName;
  @Input()
  externalLink;
  @Input()
  iframeSrc;

  currentHelpPage$: Observable<HelpPage>;
  _currentPage;
  constructor(private store: Store<fromCredentials.State>) {}

  ngOnInit(): void {
    this.currentHelpPage$ = this.store.pipe(
      select(currentHelpPage),
      tap((current) => {
        this._currentPage = current;
      })
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this._currentPage === this.page) {
      this.dispatchThis();
    }
  }

  handleClick() {
    if (this.externalLink) return;
    this.dispatchThis();
  }

  dispatchThis() {
    const props = {
      page: this.page,
      currentIFrameSrc: this.iframeSrc,
    };

    this.store.dispatch(changePage(props));
  }
}
