import { Injectable } from '@angular/core';
import {
  FnwTestNavigateInService,
  FnwTestNavigateToService,
  FnwReceiveMessage,
  FnwCredentialsUpdate,
  Credentials,
} from '@farmnet/webapp-loader-companion';
import { select, Store } from '@ngrx/store';
import * as fromRoot from '../reducers';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { isSameUrl } from '../helpers/compare-url';

@Injectable({
  providedIn: 'root',
})
export class IFrameService {
  private monolith: HTMLIFrameElement;
  private service: HTMLIFrameElement;

  constructor(private store: Store<fromRoot.State>, private router: Router) {}

  isMonolithActive(): boolean {
    let monolith = true;
    this.store
      .pipe(select(fromRoot.isMonolith), take(1))
      .subscribe((isMonolith) => {
        monolith = isMonolith;
      });

    return monolith;
  }

  isMonolithRoute(route: string): boolean {
    let monolithRoute = true;
    this.store
      .pipe(select(fromRoot.isMonolithRoute(route)), take(1))
      .subscribe((isMonolithRoute) => {
        monolithRoute = isMonolithRoute;
      });

    return monolithRoute;
  }

  setMonolithIframe(iframe: HTMLIFrameElement) {
    this.monolith = iframe;
  }

  setServiceIframe(iframe: HTMLIFrameElement) {
    this.service = iframe;
  }

  getServiceLocation(): Location | undefined {
    if (!this.service) {
      return;
    }

    const contentWindow = this.service.contentWindow;
    if (!contentWindow) {
      return;
    }

    return contentWindow.location;
  }

  getServiceRoute(): string | undefined {
    const iframeLocation = this.getServiceLocation();
    if (!iframeLocation) {
      return;
    }

    const serviceUrl = /\/mod\/(.*)/.exec(iframeLocation.href);

    return serviceUrl && serviceUrl[1] ? `/${serviceUrl[1]}` : undefined;
  }

  getMonolithLocation(): Location | undefined {
    if (!this.monolith) {
      return;
    }

    const contentWindow = this.monolith.contentWindow;
    if (!contentWindow) {
      return;
    }

    return contentWindow.location;
  }

  getMonolithRoute(): string | undefined {
    const iframeLocation = this.getMonolithLocation();
    if (!iframeLocation) {
      return;
    }

    return iframeLocation.hash && iframeLocation.hash.split('#')[1];
  }

  scrollToBottom() {
    if (this.isMonolithActive()) {
      this.scrollMonolithToBottom();
    } else {
      this.scrollServiceToBottom();
    }
  }

  scrollMonolithToBottom() {
    if (!this.monolith) {
      return;
    }

    const contentWindow = this.monolith.contentWindow;
    if (!contentWindow) {
      return;
    }

    contentWindow.scrollTo(0, contentWindow.document.body.scrollHeight);
  }

  scrollServiceToBottom() {
    if (!this.service) {
      return;
    }

    const contentWindow = this.service.contentWindow;
    if (!contentWindow) {
      return;
    }

    contentWindow.scrollTo(0, contentWindow.document.body.scrollHeight);
  }

  isCurrentUrl(url: string): boolean {
    if (this.isMonolithActive()) {
      return this.isCurrentMonolithUrl(url);
    } else {
      return this.isCurrentServiceUrl(url);
    }
  }

  private isCurrentMonolithUrl(url: string): boolean {
    const iframeLocation = this.getMonolithLocation();
    if (!iframeLocation) {
      return false;
    }

    const iframeUrl = iframeLocation.hash && iframeLocation.hash.split('#')[1];
    return isSameUrl(iframeUrl, url);
  }

  private isCurrentServiceUrl(url: string): boolean {
    const iframeLocation = this.getServiceLocation();
    if (!iframeLocation) {
      return false;
    }

    const serviceUrl = /\/mod\/(.*)/.exec(iframeLocation.href);

    return isSameUrl(serviceUrl ? `/${serviceUrl[1]}` : '', url);
  }

  postLocationChange(url: string) {
    const monolithActive = this.isMonolithActive();
    const monolithWillBeActive = this.isMonolithRoute(url);

    let message: FnwReceiveMessage;
    let changingService: boolean;
    if (monolithActive) {
      changingService = !monolithWillBeActive;
    } else {
      const currentRoute = this.router.url;
      const currentRouteServiceName = currentRoute.split('/')[1];
      const nextRouteServiceName = url.split('/')[1];

      changingService =
        monolithWillBeActive ||
        nextRouteServiceName !== currentRouteServiceName;
    }

    if (changingService) {
      message = this.createNavigateToService(url);
    } else {
      message = this.createNavigateInService(
        monolithActive ? url : this.stripServiceName(url)
      );
    }

    if (monolithActive) {
      this.postMonolithMessage(message);
    } else {
      this.postServiceMessage(message);
    }
  }

  postServiceNavigateInService(url: string) {
    const message = this.createNavigateInService(this.stripServiceName(url));
    this.postServiceMessage(message);
  }

  private stripServiceName(url: string) {
    return '/' + url.split('/').slice(2).join('/');
  }

  updateCredentials(credentials: Credentials) {
    const message = this.createCredentialsUpdate(credentials);
    this.postMonolithMessage(message);
    this.postServiceMessage(message);
  }

  postMonolithMessage(message: FnwReceiveMessage) {
    if (!this.monolith || !this.monolith.contentWindow) {
      return;
    }

    this.monolith.contentWindow.postMessage(message, location.origin);
  }

  postServiceMessage(message: FnwReceiveMessage) {
    if (!this.service || !this.service.contentWindow) {
      return;
    }

    this.service.contentWindow.postMessage(message, location.origin);
  }

  private createNavigateInService(url: string): FnwTestNavigateInService {
    return {
      type: 'fnw-test-navigate-in-service',
      url,
    };
  }

  private createNavigateToService(url: string): FnwTestNavigateToService {
    return {
      type: 'fnw-test-navigate-to-service',
      url,
    };
  }

  private createCredentialsUpdate(
    credentials: Credentials
  ): FnwCredentialsUpdate {
    return {
      type: 'fnw-credentials-update',
      credentials,
    };
  }
}
