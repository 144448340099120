import { Inject, Injectable } from '@angular/core';
import {
  Credentials,
  LOCATION,
  mapToLocale,
  SubscriptionState,
} from '@farmnet/webapp-loader-companion';
import { environment } from '../../environments/environment';
import formatISO9075 from 'date-fns/formatISO9075';
import { TranslocoService } from '@ngneat/transloco';
import { getAvailableLanIds } from '@farmnet/webapp-loader-companion/transloco';
import { Breakpoints, FeatureToggleService } from '@farmnet/fnx-components';
import { BreakpointObserver } from '@angular/cdk/layout';
import { first, withLatestFrom } from 'rxjs/operators';
import {
  CommercialContactService,
  NewsletterAgreement,
} from './commercial-contact.service';

export interface ModuleInfo {
  isDeveloperMode: boolean;
  monolithSegments: string[];
}

interface UserlaneData {
  [k: string]: boolean | string | number | string[] | number[];
}

interface ProductsBillingStatus {
  PAID: string[];
  TRIALWITHPROMO: string[];
  TRIALWITHOUTPROMO: string[];
}

export type Userlane = (
  method: string,
  value: string,
  data?: UserlaneData
) => void;
declare const Userlane: Userlane;

const USERLANE_TOUR: string = '43794';

@Injectable({
  providedIn: 'root',
})
export class UserlaneService {
  private initialized: boolean = false;

  constructor(
    private translocoService: TranslocoService,
    @Inject(LOCATION) private location: Location,
    private featureToggleService: FeatureToggleService,
    private breakpointObserver: BreakpointObserver,
    private commercialContactService: CommercialContactService
  ) {}

  init(credentials: Credentials) {
    const userLaneDisabled = this.featureToggleService.isFeatureActivated(
      'teamfrontend.webapp-loader.disable-userlane'
    );
    const bpObs = this.breakpointObserver
      .observe([Breakpoints.desktopAndUp])
      .pipe(first());
    const newsLetterObs = this.commercialContactService
      .getNewsLetterAgreementContact()
      .pipe(first());
    newsLetterObs
      .pipe(withLatestFrom(bpObs))
      .subscribe(([newsLetter, { matches }]) => {
        if (!userLaneDisabled && !this.initialized && credentials.loggedIn) {
          try {
            this.loadSnippet();
            this.setAgreementCommercialContact(credentials, newsLetter as any);
            this.configure(credentials, matches);
            this.initialized = true;
          } catch (ex) {
            console.log('userLane could not be initialized', ex);
          }
        }
      });
  }

  configure(credentials: Credentials, isDesktopAndUp: boolean = true) {
    const demo = !!credentials.userProperties['demo-account'];
    const licensedProductsBillingStatus = this.getLicensedProductsByBillingStatus(
      credentials
    );
    Userlane('identify', credentials.person.id, {
      demo,
      availableProducts: credentials.availableProducts,
      licensedProducts: credentials.licensedProducts,
      licensedProductsPaid: licensedProductsBillingStatus.PAID,
      licensedProductsTrialPromoCode:
        licensedProductsBillingStatus.TRIALWITHPROMO,
      licensedProductsTrialWithoutPromoCode:
        licensedProductsBillingStatus.TRIALWITHOUTPROMO,
      sumInActiveHa: credentials.stats.sumInHa,
      rights: credentials.rights,
      countryIsoCode: credentials.company.countryIsoCode,
      createdAt: formatISO9075(credentials.person.createdAt),
      release: credentials.release,
      shopCountry: credentials.company.shopCountry,
      agreementCommercialContact:
        credentials.userSettings.agreementCommercialContact,
      production: this.location.host.startsWith('app'),
      developer: this.location.host.startsWith('localhost'),
      host: this.location.host,
    });
    Userlane(
      'lang',
      mapToLocale(
        credentials.locale,
        getAvailableLanIds(this.translocoService.getAvailableLangs())
      )
    );

    if (isDesktopAndUp && !demo) {
      Userlane('startOnce', USERLANE_TOUR);
    }
    Userlane('init', environment.userLanePropertyId);
  }

  private setAgreementCommercialContact(
    credentials: Credentials,
    newsletter: NewsletterAgreement
  ) {
    credentials.userSettings.agreementCommercialContact =
      newsletter.status === 'SUBSCRIBED' ||
      newsletter.status === 'NEWSLETTER_CONFIRMATION_PENDING';
  }

  private getLicensedProductsByBillingStatus(
    credentials: Credentials
  ): ProductsBillingStatus {
    return {
      PAID: credentials.licensedProductsDetails
        .filter((p) => p.state === SubscriptionState.PAID)
        .map((p) => p.key),
      TRIALWITHPROMO: credentials.licensedProductsDetails
        .filter((p) => p.state === SubscriptionState.TRIAL && p.hasPromoCode)
        .map((p) => p.key),
      TRIALWITHOUTPROMO: credentials.licensedProductsDetails
        .filter((p) => p.state === SubscriptionState.TRIAL && !p.hasPromoCode)
        .map((p) => p.key),
    };
  }

  private loadSnippet() {
    if (environment.testing) {
      return;
    }
    // script provided by userlane
    // @ts-ignore
    // prettier-ignore
    // tslint:disable-next-line
    (function(i,s,o,g,r,a,m){i['UserlaneCommandObject']=r;i[r]=i[r]||function(){(i[r].q=i[r].q||[]).push(arguments)};a=s.createElement(o),m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)})(window,document,'script','https://cdn.userlane.com/userlane.js','Userlane');
  }
}
