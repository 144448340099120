export function isDifferentUrl(url: string, otherUrl: string): boolean {
  return !isSameUrl(url, otherUrl);
}

export function isSameUrl(url: string, otherUrl: string): boolean {
  try {
    const url1 = toUrl(url);
    const url2 = toUrl(otherUrl);

    return isSame(url1, url2);
  } catch (e) {
    return url === otherUrl;
  }
}

function toUrl(url: string): URL {
  const hasRelativeProtocol = url.startsWith('//');
  const isRelativeUrl = !hasRelativeProtocol && /^\.*\//.test(url);
  const origin = `${location.protocol}//${location.host}`; // ie 11 does not populate location.origin

  if (isRelativeUrl) {
    return new URL(url, origin);
  } else {
    return new URL(url);
  }
}

function isSame(url: URL, otherUrl: URL): boolean {
  const pathName = appendSlash(url.pathname);
  const otherPathName = appendSlash(otherUrl.pathname);

  const sameHash =
    url.hash === otherUrl.hash ||
    url.hash + '/' === otherUrl.hash ||
    url.hash === otherUrl.hash + '/';
  const sameOrigin = url.origin === otherUrl.origin;
  const samePath = pathName === otherPathName;
  const sameSearch = isSameSearchParams(
    url.searchParams || new URLSearchParams(url.search),
    otherUrl.searchParams || new URLSearchParams(otherUrl.search)
  );

  return sameHash && sameOrigin && samePath && sameSearch;
}

function appendSlash(pathname: string): string {
  return pathname.endsWith('/') ? pathname : pathname + '/';
}

function isSameSearchParams(
  params: URLSearchParams,
  otherParams: URLSearchParams
): boolean {
  const allParams = getOrderedEntries(params);
  const allOtherParams = getOrderedEntries(otherParams);

  if (allParams.length === allOtherParams.length) {
    for (let i = 0; i < allParams.length; i++) {
      const param = allParams[i];
      const otherParam = allOtherParams[i];

      if (param.value !== otherParam.value && param.key !== otherParam.key) {
        return false;
      }
    }
    return true;
  }
  return false;
}

function getOrderedEntries(
  params: URLSearchParams
): { key: string; value: string }[] {
  const allParams: { key: string; value: string }[] = [];
  params.sort();
  params.forEach((value, key) => allParams.push({ key, value }));

  return allParams;
}
