import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from './cookie.service';

export const NEWSLETTER_URL =
  '/365FarmNet/mod/signon/rest/v1.0/commercial-contact/newsletters-agreements';

export interface NewsletterAgreement {
  confirmedOn: Date;
  requestedOn: Date;
  status:
    | 'UNSUBSCRIBED'
    | 'SUBSCRIBED'
    | 'NEWSLETTER_CONFIRMATION_PENDING'
    | 'EMAIL_CONFIRMATION_PENDING';
}
@Injectable({
  providedIn: 'root',
})
export class CommercialContactService {
  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {}
  public getNewsLetterAgreementContact(): Observable<any> {
    const xsrfToken = this.cookieService.get('XSRF-TOKEN');
    return this.httpClient.get(NEWSLETTER_URL, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'X-XSRF-TOKEN': xsrfToken || 'could not read xsrf-token from cookie',
      },
    });
  }
}
