import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZenloopComponent } from './zenloop.component';

@NgModule({
  declarations: [ZenloopComponent],
  imports: [CommonModule],
  exports: [ZenloopComponent],
})
export class ZenloopModule {}
