import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromRouter from '@ngrx/router-store';

import * as fromIframe from './iframe.reducer';
import * as fromBroadcast from './broadcast.reducer';
import * as fromHelp from './help.reducer';
import * as fromModuleInfo from './module-info.reducer';

export interface State {
  router: fromRouter.RouterReducerState;
  iframe: fromIframe.State;
  broadcast: fromBroadcast.State;
  help: fromHelp.State;
  moduleInfo: fromModuleInfo.State;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  iframe: fromIframe.reducer,
  broadcast: fromBroadcast.reducer,
  help: fromHelp.reducer,
  moduleInfo: fromModuleInfo.reducer,
};

// console.log all actions
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state, action) => {
    const result = reducer(state, action);

    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production
  ? [logger]
  : [];

export const selectIframeState = createFeatureSelector<State, fromIframe.State>(
  'iframe'
);

export const isIframeLoading = createSelector(
  selectIframeState,
  fromIframe.isLoading
);

export const getIframeUrl = createSelector(
  selectIframeState,
  fromIframe.getUrl
);

export const isIframeOnBottom = createSelector(
  selectIframeState,
  fromIframe.isOnBottom
);

export const getIframeServiceUrl = createSelector(
  selectIframeState,
  fromIframe.getServiceUrl
);

export const getIframeServiceName = createSelector(
  selectIframeState,
  fromIframe.getServiceName
);

export const isIframeScrollDown = createSelector(
  selectIframeState,
  fromIframe.isScrollDown
);

export const getIframeScrollDirection = createSelector(
  selectIframeState,
  fromIframe.getScrollDirection
);

export const getIframeSection = createSelector(
  selectIframeState,
  fromIframe.getSection
);

export const getHeaderTop = createSelector(
  selectIframeState,
  fromIframe.getHeaderTop
);

export const getFooterTop = createSelector(
  selectIframeState,
  fromIframe.getFooterTop
);

export const getUrl = createSelector(selectIframeState, fromIframe.getUrl);

export const selectBroadcastState = createFeatureSelector<
  State,
  fromBroadcast.State
>('broadcast');

export const isLead = createSelector(
  selectBroadcastState,
  fromBroadcast.isLead
);

export const selectHelpState = createFeatureSelector<State, fromHelp.State>(
  'help'
);

export const isHelpOpen = createSelector(selectHelpState, fromHelp.isOpen);
export const currentHelpPage = createSelector(
  selectHelpState,
  fromHelp.currentHelpPage
);

export const currentHelpPageIFrame = createSelector(
  selectHelpState,
  fromHelp.currentIFrameSrc
);

export const selectModuleInfoState = createFeatureSelector<
  State,
  fromModuleInfo.State
>('moduleInfo');

export const isDeveloperMode = createSelector(
  selectModuleInfoState,
  fromModuleInfo.isDeveloperMode
);

export const selectRouterState = createFeatureSelector<
  State,
  fromRouter.RouterReducerState
>('router');

export const selectUrl = createSelector(selectRouterState, (state) => {
  return state && state.state && state.state.url;
});

export const isMonolith = createSelector(
  selectIframeState,
  fromIframe.isMonolith
);

export const isPageNotFound = createSelector(
  selectIframeState,
  fromIframe.isPageNotFound
);

export const isService = createSelector(
  selectIframeState,
  fromIframe.isService
);

export const isNavigating = createSelector(
  selectIframeState,
  fromIframe.isNavigating
);

export const isMonolithRoute = (route: string) =>
  createSelector(selectIframeState, (state: fromIframe.State) =>
    fromIframe.isMonolithRoute(state, route)
  );
