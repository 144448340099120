<ng-container *transloco="let t">
  <fnx-popup
    [popupHeader]="t('popupHeaderHelp')"
    [popupIsClosable]="true"
    [popupIsDraggable]="true"
    [popupIsMobileCoverAll]="true"
    [popupIsVisible]="isOpen"
    popupPosition="bottom-right"
    [popupHeaderActions]="popupHeaderActions"
    (popupClosed)="close.emit()"
  >
    <ng-container fnx-popup-content>
      <app-help-navigation
        [url]="url"
        [lang]="userLocale"
      ></app-help-navigation>
      <iframe
        class="help-iframe"
        [src]="_currentIFrameSrcSanitized"
        allowfullscreen
      ></iframe>
    </ng-container>
  </fnx-popup>
</ng-container>
